<template>
  <CModal
    @mtdclosemodal="mtdClose"
    :show="boo_modal"
    :title="title"
    :size="'lg'"
    :centered="true"
    :btn_cancel="true"
    :btn_ok="true"
    :booTitile="true"
  >
    <table class="table table-hover">
      <thead>
        <tr>
          <th width="60">N°</th>
          <th>Fecha</th>
          <th>A nombre de</th>
          <th>Serie</th>
          <th>Número</th>
          <th>Monto</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(yape, index) in yapes" :key="index">
          <td>{{ index+1}}</td>
          <td>{{ yape.voucher.date }}</td>
          <td>{{ yape.voucher.voucher_fullname }}</td>
          <td>{{ yape.voucher.serie }}</td>
          <td>{{ yape.voucher.number }}</td>
          <td>{{ yape.amount }}</td>
        </tr>
      </tbody>
    </table>
    <template #footer>
      <button @click="mtdClose" class="btn btn-danger btn-sm">
        <i class="fa fa-times" />&nbsp;Cerrar
      </button>
    </template>
  </CModal>
</template>
<script>
import CModal from "../../shared/modals/cModalFormCom.vue";
import { mapActions } from "vuex";

export default {
  name: "c-card-modal",
  components: {
    CModal,
  },
  data() {
    return {
      prefix: "beads",
      data_chargue: [],
    };
  },
  props: {
    title: String,
    boo_modal: Boolean,
    yapes: Array,
  },
  watch: {
  },
  created() {},
  methods: {
    ...mapActions(["get", "post"]),
    mtdClose() {
      this.$emit("closeModalYape");
    },
  },
};
</script>
<style scoped>
</style>
