import { render, staticRenderFns } from "./AdministradorView.vue?vue&type=template&id=937fca4e&scoped=true"
import script from "./AdministradorView.vue?vue&type=script&lang=js"
export * from "./AdministradorView.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "937fca4e",
  null
  
)

export default component.exports