<template>
  <div>
    <div class="row" id="div_conte2">
      <div id="div_conte" class="col-md-10 mx-auto">
        <div class="bg-secondary text-white bg-opacity-10">
          <div class="text-center py-4 px-5" id="scroll-div">
            <div class="row">
              <button
                class="div-button col m-2 p-2 text-white"
                @click="changePage('purchase')"
                v-if="hasRoles([4])"
              >
                COMPRA
              </button>
              <button
                class="div-button col m-2 p-2 text-white"
                @click="changePage('sale')"
                v-if="hasRoles([4])"
              >
                VENTA
              </button>
              <button
                class="div-button col m-2 p-2 text-white"
                @click="changePage('stock')"
                v-if="hasRoles([4])"
              >
                VER STOCK
              </button>
              <button
                class="div-button col m-2 p-2 text-white"
                @click="changePage('kardex')"
                v-if="hasRoles([4])"
              >
                MOVIMIENTOS
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
import { mapActions } from "vuex";

export default {
  name: "home",
  props: {
    userRoles: {
      type: Array,
      required: true,
    },
  },
  created() {
    // this.test();
    this.showBead();
  },
  data(){
    return{
      verificBead: false,
    }
  },
  methods: {
    ...mapActions(["get", "post"]),
    changePage: function (page) {
      if(page == 'sale' &&  !this.verificBead){
        Swal.fire({
              title: "No hay caja aperturada",
              icon: "warning",
            });
      }else{
        this.$emit("changePage", page);
      }
    },
    hasRoles(roles) {
      return roles.some((role) => this.userRoles.includes(role));
    },
    showBead() {
      this.get({
        url:
          this.$store.getters.get__url +
          "/beads/showBead/" +
          this.$store.getters.get_campu.value,
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          if (response.statusCode == 200) {
            this.verificBead = response.data ? true : false;
            // this.bead = this.verificBead == true ? response.data : {};
          }
        })
        .catch((errors) => {});
    },
  },
};
</script>
  
  <style scoped>
#div_conte {
  border: 1.5px solid #926cdc;
  padding: 0;
  margin: 40px 0;
}
.div-button {
  background: #926cdc;
  border-radius: 15px;
  border: none;
}
#scroll-div {
  height: auto;
  overflow-y: scroll;
}
#scroll-div > div > button {
  height: 65px;
}
@media (max-width: 1320px) {
  #div_conte {
    border: 1.5px solid #926cdc;
    padding: 0;
    margin: 60px 0;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .row {
    display: block;
  }
  #div_conte {
    border: 1.5px solid #926cdc;
    padding: 0;
    margin: 100px 0;
  }
  #scroll-div {
    height: 518px;
  }
}
@media (max-width: 768px) {
  #div_conte2 {
    padding-left: 30px;
    padding-right: 30px;
  }
  .row {
    display: block;
  }
  #scroll-div > div > button {
    height: 44px;
  }
  .container {
    font-size: 11px;
  }
  #scroll-div {
    height: 450px;
  }
}
@media (max-width: 415px) {
  #scroll-div {
    height: 450px;
  }
}
</style>
  