<template>
  <div class="fontfamily-sans-serif">
    <div class="container d-flex justify-content-around">
      <CRow>
        <CCol xs="12" sm="12" md="7" lg="7" xl="7">
          <CCard>
            <CCardHeader class="d-flex justify-content-between">
              <b><i class="fas fa-store"></i>&nbsp;Reservar</b>
              <button
                type="button"
                class="btn btn-sm btn-outline-main bg-purple color-white"
                @click="returnHome"
              >
                <i class="fas fa-home-lg-alt"></i>&nbsp;&nbsp;Inicio
              </button>
            </CCardHeader>
            <CCardBody>
              <FullCalendar :options="calendarOptions" />
            </CCardBody>
          </CCard>
        </CCol>
        <CCol xs="12" sm="12" md="5" lg="5" xl="5">
          <CCard>
            <CCardHeader class="d-flex justify-content-between">
              <span
                ><i class="fas fa-store"></i>&nbsp;Sede:
                {{
                  this.$store.getters.get_campu
                    ? this.$store.getters.get_campu.label
                    : ""
                }}</span
              >
              {{ booking.reservation_date }}
            </CCardHeader>
            <CCardBody>
              <CRow>
                <CCol sm="12" xl="6">
                  <cSelectForm
                    label="Campo Deportivo"
                    placeholder="Seleccione campo deportivo"
                    :options="data_sport_field"
                    :value.sync="booking.sport_field_id"
                    :disabled="!this.attention"
                    @change="mtdSelectCampo"
                    size="sm"
                  />
                </CCol>
                <CCol sm="12" xl="6">
                  <cSelectForm
                    label="Deporte"
                    placeholder="Seleccione deporte"
                    :options="data_sport"
                    :value.sync="booking.sport_id"
                    :disabled="!booking.sport_field_id"
                    @change="mtdSelectSport"
                    size="sm"
                  />
                </CCol>
              </CRow>
              <CRow>
                <CCol sm="12" xl="4">
                  <CInput
                    label="Hora de inicio"
                    type="time"
                    v-model="booking.start_time"
                    :min="schedule.start_time"
                    :max="schedule.end_time"
                    step="18000"
                    :disabled="!booking.sport_id"
                    @blur="determineShift"
                    size="sm"
                  />
                </CCol>
                <CCol sm="12" xl="4">
                  <cSelectForm
                    label="Horas"
                    placeholder="Seleccione cant. de horas"
                    :options="data_hours"
                    :value.sync="booking.cant_hours"
                    :disabled="!booking.start_time"
                    @change="calculateEndTime"
                    size="sm"
                  />
                </CCol>
                <CCol sm="12" xl="4">
                  <CInput
                    label="Hora Final"
                    type="time"
                    v-model="booking.end_time"
                    readonly
                    size="sm"
                  />
                </CCol>
              </CRow>
              <CRow>
                <CCol sm="4">
                  <CInput
                    label="Precio"
                    v-model="booking.total_pay"
                    readonly
                    size="sm"
                  />
                </CCol>

                <CCol sm="8" class="d-flex align-items-center">
                  <CInput
                    label="Dni"
                    placeholder="Digite dni"
                    v-model="booking.sport_man_dni"
                    maxlength="8"
                    size="sm"
                    @keyup="mtdSearchDocument"
                  />
                  <!-- <div class="mx-2 mt-3" @click="filtrarSportMan">
                    <button
                      type="button"
                      class="btn btn-sm btn-outline-main bg-purple color-white"
                      :disabled="!canSearch"
                    >
                      <i class="fas fa-search"></i>
                    </button>
                  </div> -->
                </CCol>
                <CCol sm="12">
                  <CInput
                    label="Nombre"
                    placeholder="Digite nombre"
                    v-model="booking.sport_man_name"
                    :disabled="booking.existingSportman"
                    size="sm"
                  />
                </CCol>
                <CCol sm="6">
                  <CInput
                    label="Celular"
                    placeholder="Digite celular"
                    v-model="booking.sport_man_phone"
                    maxlength="9"
                    @input="validatePhone"
                    :disabled="!booking.sport_man_name"
                    size="sm"
                  />
                  <span v-if="errors.phone" class="text-danger">{{
                    errors.phone
                  }}</span>
                </CCol>
                <CCol sm="6">
                  <div @click="mtd_action">
                    <label for="">&nbsp;</label>
                    <button
                      type="button"
                      class="btn btn-sm btn-outline-main bg-purple color-white mt-4"
                      :disabled="!canSave"
                    >
                      <i class="fas fa-calendar-check"></i> Guardar
                    </button>
                  </div>
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import { bus } from "../../../main";
import FullCalendar from "@fullcalendar/vue";
import timeGridPlugin from "@fullcalendar/timegrid";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import cSelectForm from "../../shared/inputs/cSelect.vue";
import moment from "moment";

export default {
  name: "bookings",
  components: {
    FullCalendar,
    cSelectForm,
  },
  data() {
    return {
      prefix: "bookings",
      showCalendar: false,

      data_sport_field: [],
      data_sport: [],
      data_rate: [],
      data_rates: [],
      data_sportman: [],
      // existingPhone: true,
      data: [],
      myButtons: [
        {
          class: "btn-sm",
          color: "success",
          tooltip: "Agregar Usuario",
          action: "mtd_action_sportsman",
          icon: "far fa-file-image",
        },
      ],

      shifts: {
        morning: { start: "06:00", end: "11:59" },
        afternoon: { start: "12:00", end: "17:59" },
        night: { start: "18:00", end: "23:59" },
      },

      calendarOptions: {
        locale: "es",
        plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
        events: [],
        initialView: "dayGridMonth",
        // dateClick: this.handleDateClick,
        buttonText: {
          today: "Hoy",
          month: "Meses",
          week: "Semanas",
          day: "Días",
        },
        headerToolbar: {
          left: "title",
          center: "",
          right: "prev today next",
        },
        weekends: true,
        selectable: true,
        editable: false,
        validRange: {
          start: Date.now(),
        },
        dateClick: this.dateClick,
      },
      events: [],
      booking: {
        id: "",
        sport_field_id: "",
        sport_id: "",
        rate_id: "",
        sport_fieldxsport_id: "",
        reservation_date: "",
        reservation_day: "",
        sport_man_id: "",
        sport_man_name: "",
        sport_man_phone: "",
        sport_man_address: "",
        sport_man_dni: "",
        employee_id: "",
        start_time: "",
        end_time: "",
        cant_hours: "",
        subtotal_pay: "",
        total_pay: "",
        existingSportman: true,
        existingPhone: true,
      },
      schedule: {
        id: "",
        start_time: "",
        end_time: "",
        shift: "",
      },
      sport: {
        id: "",
        name: "",
      },
      quote: {
        medic_id: null,
        dateStr: "",
        dateDay: "",
        campu_id: null,
        turn: 1,
      },
      errors: {
        phone: "",
      },
      data_hours: [
        { value: "00:30", label: "30 min" },
        { value: "01:00", label: "1 Hora" },
      ],
      attention: false,
    };
  },
  computed: {
    canSave() {
      return (
        this.booking.sport_field_id &&
        this.booking.sport_id &&
        this.booking.start_time &&
        this.booking.cant_hours &&
        this.booking.total_pay &&
        this.booking.sport_man_dni &&
        this.booking.sport_man_name &&
        this.booking.sport_man_phone &&
        !this.errors.phone
      );
    },
    canSearch() {
      return this.booking.sport_man_dni.length === 8;
    },
    computedRateLabel() {
      return this.data_rate.length > 0 ? this.data_rate[0].label : "";
    },
  },
  created() {
    // this.indexSport_Field();
    this.indexRate();
    this.get_employeeid();
    this.getCurrentTime();
  },
  methods: {
    ...mapActions(["get", "post"]),
    returnHome: function () {
      this.$emit("setView", "home");
    },
    dateClick: function (info) {
      // Reset background color for all dates
      const allDates = document.querySelectorAll(".fc-daygrid-day");
      allDates.forEach((dateElement) => {
        dateElement.style.backgroundColor = ""; // Reset to default
      });
      // Change background color for the selected date
      info.dayEl.style.backgroundColor = "lightblue"; // Or any color you prefer

      const date2 = new Date(info.date);
      const auxiliar = this.fixDate(date2, "date-en");
      this.quote.dateStr = moment(auxiliar).format("DD/MM/YYYY");
      this.booking.reservation_date = this.quote.dateStr;
      this.quote.dateDay = moment(auxiliar).format("dddd");
      this.booking.reservation_day = this.quitarTildes(this.quote.dateDay);
      this.showTime();
    },
    //Selecciona día y que me cargue el horario de la sede
    showTime: function () {
      this.get({
        url:
          this.$store.getters.get__url +
          "/bookings/campu/" +
          this.$store.getters.get_campu.value +
          "/" +
          this.booking.reservation_day,
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          if (response.data.state == 1) {
            Swal.fire({
              title: "No hay atención",
              icon: "warning",
            });
            this.attention = false;
          } else {
            // this.booking.reservation_date = this.quote.dateStr;
            this.attention = true;
            response.data.data.forEach((element) => {
              this.schedule.start_time = element.start_time;
              this.schedule.end_time = element.end_time;
            });
          }
        })
        .catch((errors) => {});
    },
    indexRate: function () {
      this.get({
        url:
          this.$store.getters.get__url +
          "/bookings/rate/" +
          this.$store.getters.get_campu.value,
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          this.data_rates = [];
          response.data.rates.forEach((element) => {
            this.data_rates.push({
              value: element.id,
              shift: element.shift,
              price: element.price,
              sport_fieldxsport_id: element.sport_fieldsxsport_id,
              detail: element,
            });
          });
          this.bySport_Field();
        })
        .catch((errors) => {});
    },
    bySport_Field: function () {
      this.data_sport_field = [];
      const addedFields = new Set(); // Para almacenar los IDs de campos deportivos agregados

      this.data_rates.forEach((arr) => {
        const sportFieldId = arr.detail.sport_fieldxsport.sport_field_id;
        if (!addedFields.has(sportFieldId)) {
          // Verificar si el campo ya fue agregado
          addedFields.add(sportFieldId);
          const { id, name } = arr.detail.sport_fieldxsport.sport_field;
          this.data_sport_field.push({
            label: name,
            value: id,
          });
        }
      });
      this.mtdSelectCampo();
    },
    /* select campo*/
    mtdSelectCampo: function () {
      this.data_sport = [];
      let sport_id = -1;
      this.data_rates.forEach((arr) => {
        if (
          arr.detail.sport_fieldxsport.sport_field_id ==
          this.booking.sport_field_id
        ) {
          if (arr.detail.sport_fieldxsport.sport.id != sport_id) {
            sport_id = arr.detail.sport_fieldxsport.sport.id;
            const { id, name } = arr.detail.sport_fieldxsport.sport;
            this.data_sport.push({
              label: name,
              value: id,
            });
          }
        }
      });
      this.mtdSelectSport();
    },

    /*select sport y guardo el id de sport_fieldxsport en this.booking*/
    mtdSelectSport: function () {
      this.booking.cant_hours = "";
      this.booking.end_time = "";
      this.data_rates.forEach((arr) => {
        if (
          arr.detail.sport_fieldxsport.sport_field_id ==
            this.booking.sport_field_id &&
          arr.detail.sport_fieldxsport.sport_id == this.booking.sport_id
        ) {
          this.booking.sport_fieldxsport_id = arr.detail.sport_fieldxsport.id;
        }
      });
    },

    // Determinar si la hora elegida es mañana, tarde o noche
    determineShift: function () {
      const currentTime = this.getCurrentTime();
      const selectedTime = this.booking.start_time;

      // Obtener la fecha seleccionada
      const selectedDate = this.booking.reservation_date;

      const today = new Date();
      const auxiliar = this.fixDate(today, "date-en");
      const todayFormateado = moment(auxiliar).format("DD/MM/YYYY");

      // Si la fecha seleccionada es hoy, validar la hora
      if (selectedTime < currentTime && selectedDate === todayFormateado) {
        Swal.fire({
          title: "La hora seleccionada no puede ser anterior a la hora actual.",
          icon: "warning",
        });
        this.booking.start_time = "";
        return;
      }
      const startTime = moment(this.booking.start_time, "HH:mm");

      const { morning, afternoon, night } = this.shifts;

      if (
        startTime.isBetween(
          moment(morning.start, "HH:mm"),
          moment(morning.end, "HH:mm"),
          null,
          "[)"
        )
      ) {
        this.schedule.shift = "Dia";
      } else if (
        startTime.isBetween(
          moment(afternoon.start, "HH:mm"),
          moment(afternoon.end, "HH:mm"),
          null,
          "[)"
        )
      ) {
        this.schedule.shift = "Tarde";
      } else if (
        startTime.isBetween(
          moment(night.start, "HH:mm"),
          moment(night.end, "HH:mm"),
          null,
          "[)"
        )
      ) {
        this.schedule.shift = "Noche";
      }
      this.validateHoursInicio();
      // Resetting other fields
      this.booking.cant_hours = "";
      this.booking.end_time = "";
      this.booking.total_pay = "";
    },
    //Validar si la sede abre a la hora escogida
    validateHoursInicio: function () {
      const formattedTime = this.formatTime(this.schedule.start_time);

      if (this.booking.start_time < formattedTime) {
        Swal.fire({
          title: "La sede abre a las " + formattedTime,
          icon: "warning",
        });
        this.booking.start_time = "";
      }
    },
    formatTime: function (timeString) {
      // Dividir la cadena de tiempo en horas, minutos y segundos
      const [hours, minutes] = timeString.split(":");
      // Formatear la hora a "HH:MM"
      return `${hours}:${minutes}`;
    },
    // Calcula a que hora acaba la reservación. Se necesita(hora de inicio, cantidad de horas)
    calculateEndTime: function (event) {
      if (!event.target || !event.target.value) {
        console.error("Event target or value is undefined");
        return;
      }

      const [hours1, minutes1] = this.booking.start_time.split(":").map(Number);
      const totalMinutes1 = hours1 * 60 + minutes1;

      const [hours2, minutes2] = event.target.value.split(":").map(Number);
      const totalMinutes2 = hours2 * 60 + minutes2;

      // Sumar los minutos
      const totalMinutes = totalMinutes1 + totalMinutes2;

      // Convertir de nuevo a horas y minutos
      const resultHours = Math.floor(totalMinutes / 60);
      const resultMinutes = totalMinutes % 60;

      // Formatear el resultado para que siempre tenga dos dígitos en los minutos
      const formattedHours = resultHours.toString().padStart(2, "0");
      const formattedMinutes = resultMinutes.toString().padStart(2, "0");

      this.booking.end_time = `${formattedHours}:${formattedMinutes}`;
      this.showPrice();
    },

    showPrice: function () {
      this.get({
        url:
          this.$store.getters.get__url +
          "/bookings/price/" +
          this.booking.reservation_date +
          "/" +
          this.booking.start_time +
          "/" +
          this.booking.end_time +
          "/" +
          this.booking.reservation_day +
          "/" +
          this.schedule.shift +
          "/" +
          this.booking.sport_fieldxsport_id,
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          if (response.data.state == 1) {
            Swal.fire({
              title: "Canchita Ocupada",
              text:
                response.data.data.start_time +
                " hasta " +
                response.data.data.end_time,
              icon: "warning",
            });
          } else if (response.data.state == 2) {
            Swal.fire({
              title: "Agregue Tarifa",
              icon: "warning",
            });
            this.booking.reservation_date = this.quote.dateStr;
          } else {
            response.data.data.forEach((element) => {
              this.booking.rate_id = element.id;
              this.booking.subtotal_pay = element.price;
            });
            let subtotalPay = parseFloat(this.booking.subtotal_pay);
            if (this.booking.cant_hours === "00:30") {
              this.booking.total_pay = (subtotalPay / 2).toFixed(2);
            } else if (this.booking.cant_hours === "01:00") {
              this.booking.total_pay = (subtotalPay * 1).toFixed(2);
            }
          }
        })
        .catch((errors) => {});
    },
    get_employeeid: function () {
      this.get({
        url:
          this.$store.getters.get__url +
          "/bookings/employee/" +
          this.$store.getters.get__user_id,
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          response.data.data.forEach((element) => {
            this.booking.employee_id = element.id;
          });
        })
        .catch((errors) => {});
    },

    mtd_action: function () {
      if (!this.validateForm()) return;
      let color = "success";
      let message = "";
      this.post({
        url: this.$store.getters.get__url + "/bookings/store",
        token: this.$store.getters.get__token,
        params: this.booking,
      })
        .then((response) => {
          if (response.data.state == 1) {
            Swal.fire({
              title: "Canchita Ocupada",
              icon: "warning",
            });
          } else {
            let color = "";
            let message = "";
            if (response.statusCode == 200) {
              color = "success";
              message = "REGISTRADO CORRECTAMENTE";
            } else {
              color = "danger";
              message = "error";
            }
            this.booking = {
              id: "",
              sport_field_id: "",
              sport_id: "",
              rate_id: "",
              sport_fieldxsport_id: "",
              reservation_date: "",
              reservation_day: "",
              start_time: "",
              end_time: "",
              cant_hours: "",
              subtotal_pay: "",
              total_pay: "",
              sport_man_id: "",
              sport_man_name: "",
              sport_man_phone: "",
              sport_man_address: "",
              sport_man_dni: "",
              employee_id: "",
              existingSportman: true,
              existingPhone: true,
            };
            this.attention = false;
            bus.$emit("alert", {
              color: color,
              message: message,
            });
          }
          this.get_employeeid();
        })
        .catch((errors) => {
          console.error(errors);
          bus.$emit("alert", {
            color: "danger",
            message: errors,
          });
        });
    },
    filtrarPhone: function () {
      this.get({
        url:
          this.$store.getters.get__url +
          "/bookings/sportman/" +
          this.booking.sport_man_dni,
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          if (response.data.state == 1) {
            this.booking.sport_man_phone = "";
            this.booking.sport_man_address = "";
          } else {
            response.data.data.forEach((element) => {
              this.booking.sport_man_phone = element.phone;
              this.booking.sport_man_id = element.id;
            });
          }
        })
        .catch((errors) => {});
    },

    fixDate(date, type) {
      let dateFixed = "";
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let day = date.getDate();
      let hours = "";
      switch (type) {
        case "date-es":
          if (date.getDate() < 10) day = "0" + day;
          dateFixed = `${day}/${month}/${year}`;
          break;
        case "date-en":
          if (date.getDate() < 10) day = "0" + day;
          dateFixed = `${year}-${month}-${day}`;
          break;
        case "time":
          date.getHours() < 10
            ? (hours += "0" + date.getHours())
            : (hours = date.getHours());
          dateFixed = `${hours}:${date
            .getMinutes()
            .toString()
            .padStart(2, "0")}`;
          break;

        default:
          break;
      }
      return dateFixed;
    },
    mtdSearchDocument: function () {
      if (this.booking.sport_man_dni.length == 8) {
        this.get({
          url:
            this.$store.getters.get__url +
            "/Consultas/" +
            this.booking.sport_man_dni,
          token: this.$store.getters.get__token,
        }).then((response) => {
          if (response.data.boo == 3) {
            this.booking.sport_man_dni = "";
            this.booking.sport_man_name = "";
            this.booking.sport_man_address = "";
            this.booking.existingSportman = false;

            // this.$refs.SweetAlert.showWarning("DNI no encontrado");
            Swal.fire({
              title: "DNI no encontrado",
              icon: "warning",
            });
          } else {
            // console.log(response);
            this.booking.sport_man_name =
              response.data.name + " " + response.data.last_name;
            this.booking.sport_man_address = response.data.direccion;
            this.filtrarPhone();
          }
        });
      }
    },
    quitarTildes: function (cadena) {
      return cadena.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    },

    validateDNI: function (dni) {
      const dniPattern = /^[0-9]{8}$/;
      return dniPattern.test(dni);
    },
    validatePhone() {
      if (
        this.booking.sport_man_phone.length != 9 ||
        isNaN(this.booking.sport_man_phone)
      ) {
        this.errors.phone =
          "El teléfono debe ser un número de máximo 9 dígitos";
      } else {
        this.errors.phone = "";
      }
    },
    validateForm() {
      this.validatePhone();
      return this.errors.phone === "";
    },
    getCurrentTime() {
      const now = new Date();
      const hours = now.getHours().toString().padStart(2, "0");
      const minutes = now.getMinutes().toString().padStart(2, "0");
      return `${hours}:${minutes}`;
    },
  },
};
</script>

<style scoped>
.bg-purple {
  background-color: #926cdc;
}

.color-white {
  color: white;
  /* display: flex;
    flex-wrap: wrap; */
}

.fontfamily-sans-serif {
  font-family: sans-serif;
}

#newSportsmanLink {
  text-decoration: none;
  color: white;
  background-color: #926cdc;
  /* font-weight: bold; */
  text-align: center;
  padding: 8px 10px 10px 10px;
  border-radius: 6px;
}

@media only screen and (max-width: 1200px) {
  .newReserva {
    margin: 20px 0 0 0;
  }
}
</style>