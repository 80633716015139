<template>
  <div>
    <CRow>
      <CCol xs="6" sm="6" md="6" lg="6" xl="6" class="mb-2">
        <button
          type="button"
          class="btn btn-sm btn-outline-main bg-purple color-white"
          @click="returnHome"
        >
          <i class="fas fa-home-lg-alt"></i>&nbsp;&nbsp;Inicio
        </button>
      </CCol>
      <CCol xs="6" sm="6" md="6" lg="6" xl="6" class="mb-2 text-right">
        <button @click="mtdCloseBead" class="btn btn-sm btn-info btn-md">
          <b><i class="fas fa-cash-register"></i>&nbsp;Cerrar Caja</b>
        </button>
      </CCol>
      <CCol xs="12" sm="12" md="12" lg="6" xl="6">
        <CCard>
          <CCardHeader class="bg-gris text-center font-weight-bold">
            <span>Total Efectivo</span>
          </CCardHeader>
          <CCardBody class="border">
            <div>Apertura: {{ this.bead.initial }}</div>
          </CCardBody>
          <CCardBody
            class="border"
            style="cursor: pointer"
            @click="mtdOpenModalIncome"
          >
            <div>Ingresos: {{ this.bead.income }}</div>
          </CCardBody>
          <CCardBody class="border">
            <div>Egresos: {{ this.bead.expense }}</div>
          </CCardBody>
          <CCardBody class="border">
            <div>
              <b>Total: {{ this.bead.final }} </b>
            </div>
          </CCardBody>
        </CCard>
      </CCol>
      <CCol xs="12" sm="12" md="12" lg="6" xl="6">
        <CCard>
          <CCardHeader class="bg-gris text-center font-weight-bold">
            <span>Otros Ingresos</span>
          </CCardHeader>
          <CCardBody
            class="border"
            style="cursor: pointer"
            @click="mtdOpenModalTransfer"
          >
            <div>Transferencia: {{ this.bead.transfer }}</div>
          </CCardBody>
          <CCardBody
            class="border"
            style="cursor: pointer"
            @click="mtdOpenModalCard"
          >
            <div>Tarjeta: {{ this.bead.card }}</div>
          </CCardBody>
          <CCardBody
            class="border"
            style="cursor: pointer"
            @click="mtdOpenModalDeposit"
          >
            <div>Deposito: {{ this.bead.deposit }}</div>
          </CCardBody>
          <CCardBody
            class="border"
            style="cursor: pointer"
            @click="mtdOpenModalYape"
          >
            <div>Yape: {{ this.sumaYape() }}</div>
          </CCardBody>
        </CCard>
        <div class="d-flex justify-content-end"></div>
      </CCol>
    </CRow>
    <div class="container mx-auto p-4">
      <cModalIncome
        :title="modalIncome.title"
        :boo_modal="modalIncome.modal_form"
        :incomes="modalIncome.income"
        @closeModalIncome="closeModalIncome"
      />
      <cModalTransfer
        :title="modalTransfer.title"
        :boo_modal="modalTransfer.modal_form"
        :transfers="modalTransfer.transfer"
        @closeModalTransfer="closeModalTransfer"
      />
      <cModalCard
        :title="modalCard.title"
        :boo_modal="modalCard.modal_form"
        :cards="modalCard.card"
        @closeModalCard="closeModalCard"
      />
      <cModalDeposit
        :title="modalDeposit.title"
        :boo_modal="modalDeposit.modal_form"
        :deposits="modalDeposit.deposit"
        @closeModalDeposit="closeModalDeposit"
      />
      <cModalYape
        :title="modalYape.title"
        :boo_modal="modalYape.modal_form"
        :yapes="modalYape.yape"
        @closeModalYape="closeModalYape"
      />
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import cModalIncome from "../modals/cModalIncome.vue";
import cModalTransfer from "../modals/cModalTransfer.vue";
import cModalCard from "../modals/cModalCard.vue";
import cModalDeposit from "../modals/cModalDeposit.vue";
import cModalYape from '../modals/cModalYape.vue';
export default {
  name: "close-bead",
  components: {
    cModalIncome,
    cModalTransfer,
    cModalCard,
    cModalDeposit,
    cModalYape,
  },
  data() {
    return {
      prefix: "beads",
      data_charge: [],
      data_ingresos: [],
      data_transferencia: [],
      data_tarjeta: [],
      data_deposito: [],
      data_yape: [],
      total_yape: 0,
      verificBead: false,
      bead: {
        id: "",
        box_id: "",
        user_id: this.$store.getters.get__user_id,
        campu_id: this.$store.getters.get_campu.value,
        initial: "",
        final: "",
        expense: "",
        income: "",
        card: "",
        turned: "",
        transfer: "",
        retirement: "",
        status_open: "",
        status_close: "",
        deposit: "",
        authorize: "",
        state: "",
      },
      process: {
        box_id: "",
        bead_id: "",
        otroIngresos: [
          {
            label: "Transferencia",
            total: 0,
          },
          {
            label: "Tarjeta",
            total: 0,
          },
          {
            label: "Deposito",
            total: 0,
          },
          {
            label: "Yape",
            total: 0,
          },
          {
            label: "Plin",
            total: 0,
          },
        ],
        observation: "",
        incomes: [], //todos los charges de esa caja
        expenses: [],
        totIncome: 0,
        totExpense: 0,
        retirement: 0,
        initial: 0,
        final: 0,
      },
      modalIncome: {
        title: "Ingresos",
        modal_form: false,
        income: [],
      },
      modalTransfer: {
        title: "Transferencia",
        modal_form: false,
        transfer: [],
      },
      modalCard: {
        title: "Tarjeta",
        modal_form: false,
        card: [],
      },
      modalDeposit: {
        title: "Deposito",
        modal_form: false,
        deposit: [],
      },
      modalYape: {
        title: "Yape",
        modal_form: false,
        yape: [],
      },
    };
  },
  watch: {
    "bead.id": function (newId) {
      if (newId) {
        this.mtdReportBeads();
        this.showChargue();
      }
    },
  },
  created() {
    this.showBead();
  },
  methods: {
    ...mapActions(["get", "post"]),
    returnHome: function () {
      this.$emit("setView", "home");
    },
    showBead() {
      this.get({
        url:
          this.$store.getters.get__url +
          "/beads/showBead/" +
          this.$store.getters.get_campu.value,
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          if (response.statusCode == 200) {
            this.verificBead = response.data ? true : false;
            this.bead = this.verificBead == true ? response.data : {};
          }
        })
        .catch((errors) => {});
    },
    mtdReportBeads: function () {
      this.get({
        url:
          this.$store.getters.get__url + "/beads/reportClose/" + this.bead.id,
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          if (response.statusCode == 200) {
            this.process.incomes = response.data;
            this.process.initial = this.bead.initial;
            this.process.totIncome = this.bead.income;
            this.process.final = this.bead.final;
            this.process.bead_id = this.bead.id;
            this.process.box_id = this.bead.box_id;
            this.completedJson();
          }
        })
        .catch((errors) => {});
    },
    completedJson: function () {
      const totals = {
        EFECTIVO: 0,
        DEPOSITO: 0,
        TARJETA: 0,
        YAPE: 0,
        TRANSFERENCIA: 0,
        // Agreagar más métodos
      };

      this.process.incomes.forEach((element) => {
        if (totals.hasOwnProperty(element.method)) {
          totals[element.method] += parseFloat(element.amount);
        }
      });
      //completar el json
      this.process.otroIngresos.forEach((ele) => {
        if (ele.label == "Transferencia") {
          ele.total = parseFloat(totals["TRANSFERENCIA"]);
        }
        if (ele.label == "Tarjeta") {
          ele.total = parseFloat(totals["TARJETA"]);
        }
        if (ele.label == "Deposito") {
          ele.total = parseFloat(totals["DEPOSITO"]);
        }
        if (ele.label == "Yape") {
          ele.total = parseFloat(totals["YAPE"]);
        }
      });
    },
    mtdCloseBead() {
      //cerrar caja
      this.post({
        url: this.$store.getters.get__url + "/beads/close",
        token: this.$store.getters.get__token,
        params: {
          bead: this.bead,
          process: this.process,
        },
      })
        .then((response) => {
          if (response.statusCode == 200) {
            Swal.fire({
              title: "Caja Cerrada Correctamente",
              icon: "success",
            });
            //volver
            this.returnHome();
          }
        })
        .catch((errors) => {
          bus.$emit("alert", {
            color: "danger",
            message: errors,
          });
        });
    },
    mtdOpenModalIncome() {
      this.modalIncome = {
        title: "Ingresos",
        modal_form: true,
        income: this.data_ingresos,
      };
    },
    closeModalIncome() {
      this.modalIncome = {
        modal_form: false,
      };
    },
    mtdOpenModalTransfer() {
      this.modalTransfer = {
        title: "Transferencia",
        modal_form: true,
        transfer: this.data_transferencia,
      };
    },
    closeModalTransfer() {
      this.modalTransfer = {
        modal_form: false,
      };
    },
    mtdOpenModalCard() {
      this.modalCard = {
        title: "Tarjeta",
        modal_form: true,
        card: this.data_tarjeta,
      };
    },
    closeModalCard() {
      this.modalCard = {
        modal_form: false,
      };
    },
    mtdOpenModalDeposit() {
      this.modalDeposit = {
        title: "Deposito",
        modal_form: true,
        deposit: this.data_deposito,
      };
    },
    closeModalDeposit() {
      this.modalDeposit = {
        modal_form: false,
      };
    },
    mtdOpenModalYape() {
      this.modalYape = {
        title: "Yape",
        modal_form: true,
        yape: this.data_yape,
      };
    },
    closeModalYape() {
      this.modalYape = {
        modal_form: false,
      };
    },
    showChargue() {
      this.get({
        url:
          this.$store.getters.get__url +
          "/" +
          this.prefix +
          "/filterChargue/" +
          this.$store.getters.get_campu.value +
          "/" +
          this.bead.id,
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          if (response.statusCode == 200) {
            this.data_charge = response.data;
            this.filterIncomes();
          }
        })
        .catch((errors) => {});
    },
    filterIncomes() {
      this.data_charge.forEach((element) => {
        if (element.method == "EFECTIVO") {
          this.data_ingresos.push(element);
        }
        if (element.method == "TRANSFERENCIA") {
          this.data_transferencia.push(element);
        }
        if (element.method == "TARJETA") {
          this.data_tarjeta.push(element);
        }
        if (element.method == "DEPOSITO") {
          this.data_deposito.push(element);
        }
        if (element.method == "YAPE") {
          this.data_yape.push(element);
        }
      });
    },
    sumaYape() {
      const suma = this.data_yape.reduce(
        (total, item) => total + parseFloat(item.amount),
        0
      );
      const total = suma.toFixed(2);
      return total
    },
  },
};
</script>
<style lang="scss" scoped>
.bg-gris {
  background-color: #dee2e6;
}
.bg-purple {
  background-color: #926cdc;
}
.color-white {
  color: white;
  /* display: flex;
    flex-wrap: wrap; */
}
</style>