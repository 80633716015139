<template>
  <div>
    <br>
    <CRow class="float-right">
      <CCol md="12" lg="12" sm="12" xs="12" >
        <button @click="mtdBackRol" type="button" class="btn btn-sm text-white" style="background: #926cdc"><i class="fas fa-hand-point-left"></i> Cambiar de rol</button>
      </CCol>
    </CRow>
    <br>
    <CRow class="mt-4">
      <CCol sm="6" lg="3">
        <CWidgetProgress
          header="100"
          text="Citas del día"
          color="gradient-success"
          inverse
        />
      </CCol>
      <CCol sm="6" lg="3">
        <CWidgetProgress
          header="12.124"
          text="Total Caja"
          color="gradient-info"
          inverse
        />
      </CCol>
      <CCol sm="6" lg="3">
        <CWidgetProgress
          header="$98.111,00"
          text="Total efectivo"
          color="gradient-warning"
          inverse
        />
      </CCol>
      <CCol sm="6" lg="3">
        <CWidgetProgress
          header="2"
          text="Citas canceladas"
          color="gradient-danger"
          inverse
        />
      </CCol>
    </CRow>
  </div>
</template>
<script>
export default {
  methods: {
    mtdBackRol: function(){
      this.$store.commit("mt_set_rol", null);
    }
  },
};
</script>

<style lang="scss" scoped></style>
