<template>
    <div>
      <div class="">
        <HomeVue v-if="view == 'home'" />
        <MaintenanceVue v-if="view == 'mantenimiento'" @mtdViewFooter="mtdViewFooter" />
      </div>
        <FooterVue :view="view" @setView="setView" :booFooter="booFooter" />
    </div>
  </template>
  
  <script>
  import FooterVue from "../../components/administrador/Footer.vue";
  import HomeVue from "../../components/administrador/Home.vue";
  import MaintenanceVue from "../maintenance/MaintenanceView.vue";
  
  export default {
    components: { FooterVue, HomeVue, MaintenanceVue },
    data() {
      return {
        view: "home",
        booFooter:true
      };
    },
    methods: {
      setView: function (payload) {
        this.view = payload;
      },
      mtdViewFooter: function(boo,view){
        this.booFooter = boo;
        if(boo == true) this.view = view;
      }
    },
  };
  </script>
  
  <style lang="scss" scoped></style>
  