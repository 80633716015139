<template>
  <CModal
    @mtdclosemodal="mtdClose"
    :show="boo_modal"
    :title="title"
    :size="'lg'"
    :centered="true"
    :btn_cancel="true"
    :btn_ok="true"
    :booTitile="true"
  >
    <table class="table table-hover">
      <thead>
        <tr>
          <th width="60">N°</th>
          <th>Fecha</th>
          <th>A nombre de</th>
          <th>Serie</th>
          <th>Número</th>
          <th>Monto</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(income, index) in incomes" :key="index">
          <td>{{ index+1}}</td>
          <td>{{ income.voucher.date }}</td>
          <td>{{ income.voucher.voucher_fullname }}</td>
          <td>{{ income.voucher.serie }}</td>
          <td>{{ income.voucher.number }}</td>
          <td>{{ income.amount }}</td>
        </tr>
      </tbody>
      <!-- <tfoot>
        <tr>
          <td colspan="5" class="text-right font-weight-bold">Total:</td>
          <td class="font-weight-bold">{{ totalAmount }}</td>
        </tr>
      </tfoot> -->
    </table>
    <template #footer>
      <button @click="mtdClose" class="btn btn-danger btn-sm">
        <i class="fa fa-times" />&nbsp;Cerrar
      </button>
    </template>
  </CModal>
</template>
<script>
import CModal from "../../shared/modals/cModalFormCom.vue";
import { mapActions } from "vuex";

export default {
  name: "c-income-modal",
  components: {
    CModal,
  },
  data() {
    return {
      prefix: "beads",
      data_chargue: [],
    };
  },
  props: {
    title: String,
    boo_modal: Boolean,
    incomes: Array,
  },
  watch: {
  },
  computed:{
    // totalAmount() {
    //   return this.incomes.reduce((total, incomes) => {
    //     return total + parseFloat(incomes.amount);
    //   }, 0).toFixed(2); // Asegúrate de mostrar solo dos decimales
    // },
  },
  created() {},
  methods: {
    ...mapActions(["get", "post"]),
    mtdClose() {
      this.$emit("closeModalIncome");
    },
  },
};
</script>
<style scoped>
</style>
