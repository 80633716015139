<template>
  <div>
    <CRow>
      <CCol md="12" lg="12" xl="12" xxl="12" xs="12" sm="12">
        <CTableWrapper
          :items="data"
          :fields="fields"
          hover
          :striped="true"
          :border="true"
          small
          fixed
          caption="Lista de Imagenes"
          icon="fas fa-coins"
          btn_name="Imagen"
          @mtdBack="mtdBack"
          @show_modal="mtd_open_modal"
          @action_edit="mtd_show"
          @action_delete="mtd_delete"
          :size="'md'"
          :button_new="true"
          :actions="true"
          :withActions="'2%'"
          :buttonEdit="true"
          :buttonDelete="true"
        />
      </CCol>
    </CRow>

    <!-- modal -->
    <CModalForm
      :backdrop="true"
      :closeOnBackdrop="true"
      :size="'md'"
      :title="modal.title"
      :button="cp_button"
      :show.sync="modal.modal_form"
      @mtd_action="mtd_action"
      :color="'info'"
      :centered="true"
    >
      <CRow>
        <CCol sm="12">
          <cSelectForm
            label="Campo deportivo"
            placeholder="Seleccione campo deportivo"
            :options="data_sportField"
            :value.sync="image.sport_field_id"
          />
          <input
            @change="getFile"
            type="file"
            class="form-control"
            id="file"
            lang="es"
            name="file"
            ref="fileInput"
            accept=".png,.jpg,.jpeg"
          />
          <div v-if="image.path && modal.action !== 'store'" class="container-img">
            <img :src="'https://iareservagol.howertpi.com/assets/'+image.path" class="img-thumbnail" style="max-width: 100px;" alt="campo deportivo">
          </div>
        </CCol>

        <!-- <CCol v-if="modal.action != 'store'" sm="12">
          <label for="">Estado</label>
          <CSwitchVue
            :key="componentKey"
            @changeSwitch="changeSwitch"
            class="mx-1 pl-2 pt-2"
            :checked="schedule.state == 1 ? true : false"
            color="success"
            variant="opposite"
            shape="pill"
            v-bind="labelIcon"
          />
        </CCol> -->
      </CRow>
    </CModalForm>

    <!-- modal delete -->
    <cModalDelete
      :backdrop="true"
      :closeOnBackdrop="true"
      :size="'md'"
      :title="'Borrar Image'"
      :show.sync="modal_delete.boo"
      @mtd_commit="mtd_commit"
      :centered="true"
      :item="modal_delete.item"
    />
  </div>
</template>

<script>
const fields = [
  { key: "index", label: "Id", _style: "width:1%" },
  { key: "sport_field_name", label: "Campo Deportivo", _style: "width:20%;" },
  { key: "path", label: "Imagen", _style: "width:20%;" },
];

import CTableWrapper from "../shared/datatable/Table.vue";
import CModalForm from "../shared/modals/cModalForm.vue";
import cModalDelete from "../shared/modals/cModalDelete.vue";
import { mapActions } from "vuex";
import { bus } from "../../main";
import { CSwitch } from "@coreui/vue-pro";
import CSwitchVue from "../shared/switch/CSwitch.vue";
import cSelectForm from "../shared/inputs/cSelect.vue";

export default {
  components: { CTableWrapper, CModalForm, cModalDelete, CSwitchVue, cSelectForm },
  data() {
    return {
      prefix: "images",
      fields,
      data: [],
      data_sportField: [],
      modal: {
        action: "",
        title: "",
        modal_form: false,
      },
      image: {
        id: "",
        sport_field_id:"",
        sport_field_name:"",
        path:"",
        campu: "",
        // state: 0,
      },
      modal_delete: {
        boo: false,
        item: [],
      },
      labelIcon: {
        labelOn: "\u2713",
        labelOff: "\u2715",
      },
      componentKey: 0,
      // dataStore: [],
    };
  },
  computed: {
    cpStatus: function () {
      if (this.image.state == 1) return true;
      return false;
    },
    cp_button: function () {
      if (this.image.sport_field_id) return false;
      return true;
    },
  },
  created() {
    this.mtd_getdata();
    this.indexSportField();
  },
  methods: {
    ...mapActions(["get", "post"]),
    indexSportField:function(){
      let url="";
      if(this.$store.getters.get__user_id == 1){
        url = this.$store.getters.get__url + "/images/sportfield/"+this.$store.getters.get__user_id+"/1";
      }else{
        url = this.$store.getters.get__url + "/images/sportfield/"+this.$store.getters.get__user_id+"/"+this.$store.getters.get_campu.value;
      }
      this.get({
        url: url,
        token: this.$store.getters.get__token,
      })
      .then((response) => {
          response.data.data.forEach((element) => {
            this.data_sportField.push({
              label: element.name,
              value: element.id,
            });
          });
        })
        .catch((errors) => {});
    },
    getFile(e) {
      let fileInput = this.$refs.fileInput;
		  let file = e.target.files[0];
		  if (!/\.(png)$/i.test(file.name) && !/\.(jpg)$/i.test(file.name) &&  !/\.(jpeg)$/i.test(file.name)) {
        fileInput.value = '';
		  } else {
    		this.image.path = file;
		  }
	  },
    mtd_getdata: function () {
      let url;
      if(this.$store.getters.get__user_id==1){
        url = this.$store.getters.get__url + "/" + this.prefix+"/index/"+this.$store.getters.get__user_id+"/1";
      }else{
        url= this.$store.getters.get__url + "/" + this.prefix+"/index/"+this.$store.getters.get__user_id+"/"+this.$store.getters.get_campu.value;
      }
      this.get({
        // url: this.$store.getters.get__url + "/" + this.prefix+"/index/"+this.$store.getters.get_all_user.id+"/"+this.$store.getters.get_campu.value,
        url:url,
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          this.data = response.data.images;
          let index = 1;
          this.data.forEach((arr)=>{
            arr.index = index;
            index++;
          })
        })
        .catch((errors) => {
          // this.errorsBackend = errors;
          // this.$emit("error", this.event);
        });
    },
    mtdBack: function () {
      this.$emit("mtdBack");
    },
    mtd_open_modal: function (boo, action) {
      this.modal.modal_form = boo;
      this.modal.action = action;
      action == "store"
        ? (this.modal.title = "Nueva Imagen")
        : (this.modal.title = "Editar Imagen");
      action == "store"
        ? (this.image = {
            id: "",
            sport_field_id:"",
            path:"",
            // state: "",
          })
        : (this.image = {...this.image});
      if (this.modal.action != "store") {
        this.componentKey += 1;
      }
    },
    mtd_action: function () {
      let formData = new FormData();
      formData.append("id", this.image.id);
      formData.append("sport_field_id", this.image.sport_field_id);
      formData.append("path", this.image.path);
      formData.append("campu", this.image.campu);
     
      this.post({
        url:
          this.$store.getters.get__url +
          "/" +
          this.prefix +
          "/" +
          this.modal.action,
        token: this.$store.getters.get__token,
        params: formData,
      })
        .then((response) => {
          let color = "success";
          let message = "";
          if (this.modal.action == "store") {
            message = "REGISTRADO CORRECTAMENTE";
            this.mtd_getdata();
          } else {
            let updated = response.data.data;
            let index = this.data.findIndex(image => image.id === updated.id);
            if (index !== -1) {
              this.$set(this.data, index, updated);
            }
            message = "EDITADO CORRECTAMENTE";
            this.mtd_getdata();
          }
          this.modal = {
            action: "",
            title: "",
            modal_form: false,
            name: "",
          };
          this.image = { id: "",sport_field_id:"", path: ""}; 

          bus.$emit("alert", {
            color: color,
            message: message,
          });
        })
        .catch((errors) => {
          bus.$emit("alert", {
            color: "danger",
            message: errors,
          });
        });
    },
    mtd_show: function (id) {
      this.get({
        url:
          this.$store.getters.get__url + "/" + this.prefix + "/" + id + "/show",
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          this.image = response;
          if (this.image.path) {
            let url_imagen = this.image.path;
            let url = this.$store.getters.get__url;
            let urlModifie = url.replace("api/v1","");
            let url_imagenModifie = url_imagen.replace("http://localhost/",urlModifie); 
            this.image.path = url_imagenModifie;
          }
          this.mtd_open_modal(true, "update");
        })
        .catch((errors) => {});
    },
    mtd_delete: function (item) {
      this.modal_delete.boo = true;
      this.modal_delete.item = item;
    },
    close_delete: function () {
      this.modal_delete.boo = false;
    },
    mtd_commit: function () {
      this.post({
        url: this.$store.getters.get__url + "/" + this.prefix + "/destroy",
        token: this.$store.getters.get__token,
        params: {
          id: this.modal_delete.item.id,
        },
      })
        .then((response) => {
          let temp = [];
          this.data.forEach((element) => {
            if (element.id != response.data.id) {
              temp.push(element);
            }
          });
          this.data = temp;
          this.modal_delete = {
            boo: false,
            item: [],
          };

          bus.$emit("alert", {
            color: "success",
            message: "ELIMINADO CORRECTAMENTE",
          });
        })
        .catch((errors) => {});
    },
    changeSwitch: function (payload) {
      this.image.state = payload == true ? 1 : 0;
    },
  },
  destroyed() {
    // console.log(this.dataStore);
    // this.$store.commit("mt_set_datacampus", this.dataStore);
  },
};
</script>

<style lang="scss" scoped>
.btn-search {
  color: #fff;
  background-color: #2819ae;
  border-color: #2517a3;
}
.container-img{
  margin: 10px 0 0 0;
  display: flex;
  justify-content: center;
}
</style>
