<template>
  <div class="container mx-auto">
    <CRow>
      <CCol xs="12" sm="12" md="12" lg="12" xxl="12">
        <CCard>
          <CCardHeader class="d-flex justify-content-between">
            <b><i class="fas fa-calendar"></i>&nbsp;Movimientos</b>
            <button
              type="button"
              class="btn btn-sm text-white"
              @click="returnHome"
              style="background: #926cdc"
            >
              <i class="fas fa-hand-point-left"></i> Retroceder
            </button>
          </CCardHeader>
          <CCardBody>
            <!-- Filtro por fechas -->
            <CRow>
              <CCol xs="12" sm="12" md="6" lg="6" xxl="6">
                <CInput
                  label="Desde"
                  type="date"
                  @input="mtdGetData"
                  v-model="kardex.init"
                  size="sm"
                />
              </CCol>
              <CCol xs="12" sm="12" md="6" lg="6" xxl="6">
                <CInput
                  label="Hasta"
                  type="date"
                  @input="mtdGetData"
                  v-model="kardex.end"
                  size="sm"
                />
              </CCol>
            </CRow>
            <!-- Fin Filtro por fechas -->
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
    <CRow>
      <CCol xs="12" sm="12" md="12" lg="12" xxl="12">
        <CCard>
          <CCardBody>
            <div id="content-timelinetable" v-if="showTable">
              <table
                id="tb-cxc"
                class="table table-bordered border-main align-middle table-responsive"
              >
                <thead>
                  <tr
                    class="border-black text-center my-auto"
                    style="background: #EBEDEF; vertical-align: middle"
                  >
                    <th
                      class="border-black"
                      scope="col"
                      colspan="1"
                      rowspan="2"
                      style="vertical-align: middle;"
                    >
                      FECHA
                    </th>
                    <th
                      class="border-black"
                      scope="col"
                      colspan="1"
                      rowspan="2"
                      style="vertical-align: middle;"
                    >
                      TIPO DOC.
                    </th>
                    <th
                      class="border-black"
                      scope="col"
                      colspan="1"
                      rowspan="2"
                      style="vertical-align: middle;white-space: nowrap; "
                    >
                      SERIE Y N° DE DOC.
                    </th>
                    <th
                      class="border-black"
                      scope="col"
                      colspan="1"
                      rowspan="2"
                      style="vertical-align: middle;"
                    >
                      TIPO OPE.
                    </th>
                    <th class="border-black" scope="col" colspan="3" style="vertical-align: middle;" >
                      INGRESOS
                    </th>
                    <th class="border-black" scope="col" colspan="3" style="vertical-align: middle;">
                      SALIDAS
                    </th>
                    <th class="border-black" scope="col" colspan="3" style="vertical-align: middle;">SALDO</th>
                  </tr>
                  <tr
                    class="border-black text-center"
                    style="background: #EBEDEF; vertical-align: middle"
                  >
                    <th class="border-black" scope="col" style="vertical-align: middle;">Cantidad</th>
                    <th class="border-black" scope="col" style="vertical-align: middle;">Costo Unit.</th>
                    <th class="border-black" scope="col" style="vertical-align: middle;">Costo Total</th>
                    <th class="border-black" scope="col" style="vertical-align: middle;">Cantidad</th>
                    <th class="border-black" scope="col" style="vertical-align: middle;">Costo Unit.</th>
                    <th class="border-black" scope="col" style="vertical-align: middle;">Costo Total</th>
                    <th class="border-black" scope="col" style="vertical-align: middle;">Cantidad</th>
                    <th class="border-black" scope="col" style="vertical-align: middle;">Costo Unit.</th>
                    <th class="border-black" scope="col" style="vertical-align: middle;">Costo Total</th>
                  </tr>
                </thead>

                <tbody class="custom-table">
                  <tr
                    class="text-center"
                    style="
                      vertical-align: middle;
                      color: #900052;
                      background: #EBEDEF;
                    "
                  >
                    <th
                      class="border-black"
                      scope="col"
                      colspan="4"
                      style="font-style: italic"
                    >
                      *** TOTALES
                    </th>
                    <th class="border-black" scope="col">
                      {{ totals.totalSumaCantidadEn }}
                    </th>
                    <th class="border-black" scope="col" colspan="2">
                      {{ totals.totalSumaTotalEn }}
                    </th>
                    <th class="border-black" scope="col">
                      {{ totals.totalSumaCantidadSa }}
                    </th>
                    <th class="border-black" scope="col" colspan="2">
                      {{ totals.totalSumaTotalSa }}
                    </th>
                    <th
                      class="border-black"
                      scope="col"
                      colspan="3"
                      style="font-style: italic"
                    ></th>
                  </tr>

                  <template v-for="(product, productId) in dataKardex">
                    <tr
                      class="text-center border-black bg-white"
                      :key="'product_' + productId"
                    >
                      <th class="border-black" scope="col" colspan="13">
                        {{ product[0].name }}( {{ product[0].name_unidad }} )
                      </th>
                    </tr>

                    <tr
                      class="border-black"
                      v-for="(item, index) in product"
                      :key="'item_' + productId + '_' + index"
                    >
                      <template v-if="item.type == 1">
                        <td class="border-black">
                          {{ item.date | formatDate }}
                        </td>
                        <td class="border-black">{{ item.documentCom }}</td>
                        <td class="border-black">{{ item.serieCom }}</td>
                        <td class="border-black" style="cursor: pointer">
                          <span
                            v-if="item.type == 1"
                            class="badge bg-success text-white p-1"
                            >ENTRADA</span
                          >
                          <span
                            v-else-if="item.type == 2"
                            class="badge bg-danger text-white p-1"
                            >SALIDA</span
                          >
                        </td>
                        <td class="border-black">{{ item.quantity }}</td>
                        <td class="border-black">{{ item.price_purchase }}</td>
                        <td class="border-black">{{ item.producto }}</td>
                        <td class="border-black" colspan="3">-</td>
                        <td class="border-black">{{ item.stock_new }}</td>
                        <td class="border-black">{{ item.price_purchase }}</td>
                        <td class="border-black">{{ item.producto_saldo }}</td>
                      </template>
                      <template v-if="item.type == 2">
                        <td class="border-black">
                          {{ item.date | formatDate }}
                        </td>
                        <td class="border-black">{{ item.document }}</td>
                        <td class="border-black">
                          {{ item.serie }}-{{ item.number }}
                        </td>
                        <td class="border-black" style="cursor: pointer">
                          <span
                            v-if="item.type == 1"
                            class="badge bg-success text-white p-1"
                            >ENTRADA</span
                          >
                          <span
                            v-else-if="item.type == 2"
                            class="badge bg-danger text-white p-1"
                            >SALIDA</span
                          >
                        </td>
                        <td class="border-black" colspan="3">-</td>
                        <td class="border-black">{{ item.quantity }}</td>
                        <td class="border-black">{{ item.price_purchase }}</td>
                        <td class="border-black">{{ item.producto }}</td>
                        <td class="border-black">{{ item.stock_new }}</td>
                        <td class="border-black">{{ item.price_purchase }}</td>
                        <td class="border-black">{{ item.producto_saldo }}</td>
                      </template>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import { bus } from "../../../../main";
import moment from "moment";

export default {
  name: "kardex",
  components: {},
  data() {
    return {
      kardex: {
        init: null,
        end: null,
        campu: this.$store.getters.get_campu.value,
      },
      dataKardex: [],
      showTable: false,
    };
  },
  computed: {},
  watch: {},
  created() {
    moment.locale("es");
    this.kardex.end =
      moment().format("L").substr(6, 4) +
      "-" +
      moment().format("L").substr(3, 2) +
      "-" +
      moment().format("L").substr(0, 2);
    this.kardex.init = this.kardex.end;
    this.mtdGetData();
  },
  methods: {
    ...mapActions(["get", "post"]),
    returnHome() {
      this.$emit("returnHome");
    },
    mtdGetData: function () {
      this.post({
        url: this.$store.getters.get__url + "/Kardex/index",
        token: this.$store.getters.get__token,
        params: this.kardex,
      })
        .then((response) => {
          this.dataKardex = response.data.data;
          this.totals = response.data.totals;
          if (this.dataKardex.length == 0) {
            this.showTable = false;
            this.noRecordsMessage = "No se encontraron Resultados";
            Swal.fire({
              title: "Sin Registros",
              icon: "warning",
            });
          } else {
            this.showTable = true;
            this.noRecordsMessage = "";
          }
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    // applyDateFilter() {
    //   this.post({
    //     url: this.$store.getters.get__url + "/" + this.prefix + "/index",
    //     token: this.$store.getters.get__token,
    //     params: this.kardex,
    //   })
    //     .then((response) => {
    //       this.data = response.data.data;
    //       // this.filterData(startDate, endDate);
    //     })
    //     .catch((errors) => {
    //       bus.$emit("alert", {
    //         color: "danger",
    //         message: errors,
    //       });
    //     });
    // },
  },
  filters: {
    formatDate(date) {
      return date != null ? moment(date).format("DD/MM/YYYY") : "-";
    },
    formatDateTime(dateTime) {
      return dateTime != null
        ? moment(dateTime).format("DD/MM/YYYY HH:mm")
        : "-";
    },
  },
};
</script>
<style scoped>
.custom-table th,
.custom-table td {
  font-size: 11px;
}

.border-black {
  border: 1.5px solid black;
}
.bg-white {
  background: #eaf4f4 !important;
}
.table-responsive {
  overflow-x: auto;
}
</style>
