<template>
  <CModal
    @mtdclosemodal="mtdClose"
    :show="boo_modal"
    :title="title"
    :product="product"
    :size="'md'"
    :centered="true"
    :btn_cancel="true"
    :btn_ok="true"
    :booTitile="true"
  >
    <CRow>
      <CCol xs="12" sm="12" md="12" lg="12" xl="12">
        <CInput
          label="Producto"
          :value="tempProduct?.product.name || ''"
          readonly
        />
      </CCol>
    </CRow>
    <cRow>
      <CCol
        xs="12"
        sm="12"
        md="12"
        lg="8"
        xl="8"
        class="d-flex justify-content-center align-items-center"
      >
        <button @click="decreaseQuantity" class="btn btn-sm btn-info py-2 px-4 menos mx-2">
          <i class="fas fa-minus"></i>
        </button>
        <CInput 
          label="Cantidad"
          v-model="tempProduct.quantity"
          class="text-center"
          @input="validationStock()"
        />
        <button @click="increaseQuantity" class="btn btn-sm btn-primary py-2 px-4 mx-2 mas">
          <i class="fas fa-plus"></i>
        </button>
      </CCol>
      <CCol xs="12" sm="12" md="12" lg="4" xl="4">
        <CInput 
          label="Precio" 
          :value="tempProduct.price || 1"
          readonly 
        />
      </CCol>
    </cRow>
    <template #footer>
      <button @click="mtdClose" class="btn btn-sm">
        <i class="fa fa-times" />&nbsp;Cerrar
      </button>
      <button @click="save" class="btn btn-info btn-sm">Guardado</button>
      &nbsp;
    </template>
  </CModal>
</template>
<script>
import CModal from "../../../shared/modals/cModalFormCom.vue";

export default {
  name: "c-update-sale-modal",
  components: {
    CModal,
  },
  data() {
    return {
      tempProduct: { ...this.product }, // Copia temporal del producto
    };
  },
  props: {
    title: String,
    boo_modal: Boolean,
    product: Object,
  },
  created() {
  },
  watch: {
    boo_modal(newVal) {
      if (newVal) {
        this.tempProduct = { ...this.product }; // Resetear la copia temporal al abrir el modal
      }
    },
  },
  methods: {
    mtdClose() {
      this.$emit("closeModalUpdateSale");
    },
    increaseQuantity() {
       // Verificar que la cantidad no exceda el stock
      if (this.tempProduct.quantity < this.tempProduct.stock) {
        this.tempProduct.quantity += 1;
      } else {
        Swal.fire({
          title: "La cantidad excede del stock disponible",
          icon: "warning",
        });
      }
    },
    decreaseQuantity() {
      if (this.tempProduct.quantity > 1) {
        this.tempProduct.quantity -= 1;
      }
    },
    validationStock(){
      const quantity = parseInt(event.target.value, 10);
      // const stock = parseInt(this.tempProduct.stock, 10);
      if (quantity > this.tempProduct.stock) {
        this.tempProduct.quantity = parseInt(this.tempProduct.stock, 10);
        Swal.fire({
          title: "La cantidad excede del stock disponible",
          icon: "warning",
        });
      } else if (quantity < 1) {
        this.tempProduct.quantity = 1;
      }
    },
    save() {
      this.$emit("updateProduct", this.tempProduct); // Envía los datos al componente padre
      this.$emit("closeModalUpdateSale"); // Cierra el modal
    },
  },
};
</script>
<style scoped>
.menos {
  margin-top: 11px;
}
.mas {
  margin-top: 11px;
}
</style>
