<template>
  <div>
    <CRow>
      <CCol md="12" lg="12" xl="12" xxl="12" xs="12" sm="12">
        <CTableWrapper
          :items="data"
          :fields="fields"
          hover
          :striped="true"
          :border="true"
          small
          fixed
          caption="Lista de sedes"
          icon="fas fa-coins"
          btn_name="Sede"
          @mtdBack="mtdBack"
          @show_modal="mtd_open_modal"
          @action_edit="mtd_show"
          @action_delete="mtd_delete"
          :size="'md'"
          :button_new="true"
          :actions="true"
          :withActions="'2%'"
          :buttonEdit="true"
          :buttonDelete="true"
        />
      </CCol>
    </CRow>

    <!-- modal -->
    <CModalForm
      :backdrop="true"
      :closeOnBackdrop="true"
      :size="'md'"
      :title="modal.title"
      :button="cp_button"
      :show.sync="modal.modal_form"
      @mtd_action="mtd_action"
      :color="'info'"
      :centered="true"
    >
      <CRow>
        <CCol sm="12">
          <cSelectForm
            label="Empresa"
            placeholder="Seleccione empresa"
            :options="data_companies"
            :value.sync="campu.company_id"
            :disabled="this.$store.getters.get__user_id !== 1"
          />
        </CCol>
        <CCol sm="12">
          <CInput
            label="Nombre"
            placeholder="Digite nombre"
            v-model="campu.name"
          />
        </CCol>
        <CCol sm="12">
          <CInput
            label="Dirección"
            placeholder="Digite dirección"
            v-model="campu.address"
          />
        </CCol>
        <CCol sm="12">
          <CInput
            label="Telefono"
            placeholder="Digite telefono"
            v-model="campu.phone"
            maxlength="9"
            @input="validatePhone"
          />
          <span v-if="errors.phone" class="text-danger">{{
            errors.phone
          }}</span>
        </CCol>

        <CCol sm="12">
          <cSelectForm
            label="Departamento"
            placeholder="Seleccione departamento"
            :options="departments"
            :value.sync="campu.department_id"
            @change="fetchProvinces"
          />
        </CCol>

        <CCol sm="12">
          <cSelectForm
            label="Provincia"
            placeholder="Seleccione provincia"
            :options="provinces"
            :value.sync="campu.province_id"
            @change="fetchDistricts"
          />
        </CCol>

        <CCol sm="12">
          <cSelectForm
            label="Distrito"
            placeholder="Seleccione distrito"
            :options="districts"
            :value.sync="campu.district_id"
            @change="mtdDisctrict"
          />
        </CCol>

        <CCol v-if="modal.action != 'store'" sm="12">
          <label for="">Estado</label>
          <CSwitchVue
            :key="componentKey"
            @changeSwitch="changeSwitch"
            class="mx-1 pl-2 pt-2"
            :checked="campu.state == 1 ? true : false"
            color="success"
            variant="opposite"
            shape="pill"
            v-bind="labelIcon"
          />
        </CCol>
      </CRow>
    </CModalForm>

    <!-- modal delete -->
    <cModalDelete
      :backdrop="true"
      :closeOnBackdrop="true"
      :size="'md'"
      :title="'Borrar Rol'"
      :show.sync="modal_delete.boo"
      @mtd_commit="mtd_commit"
      :centered="true"
      :item="modal_delete.item"
    />
  </div>
</template>

<script>
const fields = [
  { key: "index", label: "Id", _style: "width:1%" },
  { key: "company_reason_social", label: "Empresa", _style: "width:20%;" },
  { key: "name", label: "Sede", _style: "width:20%;" },
  { key: "address", label: "Dirección", _style: "width:20%;" },
  { key: "phone", label: "Telefono", _style: "width:20%;" },
  { key: "state", label: "Estado", _style: "width:5%;" },
];

import CTableWrapper from "../../components/shared/datatable/Table.vue";
import CModalForm from "../../components/shared/modals/cModalForm.vue";
import cModalDelete from "../../components/shared/modals/cModalDelete.vue";
import { mapActions } from "vuex";
import { bus } from "../../main";
import { CSwitch } from "@coreui/vue-pro";
import CSwitchVue from "../shared/switch/CSwitch.vue";
import cSelectForm from "../../components/shared/inputs/cSelect.vue";

export default {
  components: {
    CTableWrapper,
    CModalForm,
    cModalDelete,
    CSwitchVue,
    cSelectForm,
  },
  data() {
    return {
      prefix: "campus",
      fields,
      data: [],
      data_companies: [],
      departments: [],
      provinces: [],
      districts: [],
      modal: {
        action: "",
        title: "",
        modal_form: false,
      },
      campu: {
        id: "",
        name: "",
        address: "",
        phone: "",
        company_id:
          this.$store.getters.get_all_user.id == 1
            ? ""
            : this.$store.getters.get_company.id,
        district_id: "",
        company_reason_social: "",
        user_id: this.$store.getters.get_all_user.id,
        state: 0,
        department_id: "",
        province_id: "",
        district_id: "",
      },
      province_id: "",
      district_id: "",
      errors: {
        phone: "",
      },
      modal_delete: {
        boo: false,
        item: [],
      },
      labelIcon: {
        labelOn: "\u2713",
        labelOff: "\u2715",
      },
      componentKey: 0,
      // dataStore: [],
    };
  },
  computed: {
    cpStatus: function () {
      if (this.campu.state == 1) return true;
      return false;
    },
    cp_button: function () {
      if (
        this.campu.name != "" &&
        this.campu.address != "" &&
        this.campu.phone != "" &&
        this.campu.phone.length == 9 &&
        this.campu.district_id != "" &&
        this.campu.company_id != ""
      )
        return false;
      return true;
    },
  },
  watch: {
    "campu.district.id": function (newId) {
      this.campu.district_id = newId;
    },
    "campu.district.department_id": function (newDepartmentId) {
      if (newDepartmentId) {
        this.fetchProvinces();
        this.campu.province_id = this.province_id;
      }
    },
    "campu.district.province_id": function (newProvinceId) {
      if (newProvinceId) {
        this.fetchDistricts();
        this.campu.district_id = this.district_id;
      }
    },
  },
  created() {
    this.mtd_getdata();
    this.fetchDepartment();
    this.indexCampus();
    // this.test();
  },
  methods: {
    ...mapActions(["get", "post"]),
    mtdDisctrict() {
      if (this.campu.district_id <= 90723) {
        this.campu.district_id = String(this.campu.district_id).padStart(
          6,
          "0"
        );
      }
    },
    fetchDepartment() {
      this.get({
        url: this.$store.getters.get__url + "/campus/get_department",
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          response.data.data.forEach((element) => {
            this.departments.push({
              label: element.name,
              value: element.id.toString(),
            });
          });
        })
        .catch((errors) => {});
    },
    fetchProvinces() {
      this.provinces = [];
      (this.campu.province_id = ""), (this.districts = []);
      this.campu.district_id = "";
      //el id de departament convertirlo a string
      if (this.campu.department_id < 10) {
        this.campu.department_id = String(this.campu.department_id).padStart(
          2,
          "0"
        );
      }
      this.get({
        url:
          this.$store.getters.get__url +
          "/campus/provinces/" +
          this.campu.department_id,
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          response.data.data.forEach((element) => {
            this.provinces.push({
              label: element.name,
              value: element.id.toString(),
            });
          });
        })
        .catch((errors) => {});
    },
    fetchDistricts() {
      this.districts = [];
      this.campu.district_id = "";
      //el id de province convertirlo a string
      if (this.campu.province_id <= 999) {
        this.campu.province_id = String(this.campu.province_id).padStart(
          4,
          "0"
        );
      }
      this.get({
        url:
          this.$store.getters.get__url +
          "/campus/districts/" +
          this.campu.province_id,
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          response.data.forEach((element) => {
            this.districts.push({
              label: element.name,
              value: element.id.toString(),
            });
          });
        })
        .catch((errors) => {});
    },
    indexCampus: function () {
      this.get({
        url: this.$store.getters.get__url + "/campus/company",
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          response.data.forEach((element) => {
            this.data_companies.push({
              label: element.reason_social,
              value: element.id,
            });
          });
        })
        .catch((errors) => {});
    },
    mtd_getdata: function () {
      this.get({
        url:
          this.$store.getters.get__url +
          "/" +
          this.prefix +
          "/index/" +
          this.$store.getters.get_all_user.id,
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          if (response.statusCode == 200) {
            this.data = response.data.data;
            let index = 1;
            this.data.forEach((arr) => {
              arr.index = index;
              index++;
            });
          }
        })
        .catch((errors) => {
          // this.errorsBackend = errors;
          // this.$emit("error", this.event);
        });
    },
    mtdBack: function () {
      this.$emit("mtdBack");
    },

    mtd_open_modal: function (boo, action) {
      this.modal.modal_form = boo;
      this.modal.action = action;
      action == "store"
        ? (this.modal.title = "Nueva Sede")
        : (this.modal.title = "Editar Sede");
      action == "store"
        ? (this.campu = {
            id: "",
            name: "",
            address: "",
            phone: "",
            company_id:
              this.$store.getters.get_all_user.id == 1
                ? ""
                : this.$store.getters.get_company.id,
            district_id: "",
            company_reason_social: "",
            user_id: this.$store.getters.get_all_user.id,
            state: 0,
            department_id: "",
            province_id: "",
            district_id: "",
          })
        : (this.campu = { ...this.campu });
      if (this.modal.action != "store") {
        this.componentKey += 1;
      }
    },
    mtd_action: function () {
      if (!this.validateForm()) return;

      this.post({
        url:
          this.$store.getters.get__url +
          "/" +
          this.prefix +
          "/" +
          this.modal.action,
        token: this.$store.getters.get__token,
        params: this.campu,
      })
        .then((response) => {
          if (response.data.state == 1) {
            Swal.fire({
              title: "Sede ya resgistrada",
              icon: "warning",
            });
          } else {
            let color = "success";
            let message = "";
            if (this.modal.action == "store") {
              message = "REGISTRADO CORRECTAMENTE";
              this.mtd_getdata();
            } else {
              let updatedCampu = response.data.data;
              let index = this.data.findIndex(
                (campu) => campu.id === updatedCampu.id
              );
              if (index !== -1) {
                this.$set(this.data, index, updatedCampu);
              }
              message = "EDITADO CORRECTAMENTE";
              this.mtd_getdata();
            }
            this.modal = {
              action: "",
              title: "",
              modal_form: false,
              name: "",
            };
            this.campu = {
              id: "",
              name: "",
              address: "",
              phone: "",
              company_id:
                this.$store.getters.get_all_user.id == 1
                  ? ""
                  : this.$store.getters.get_company.id,
              district_id: "",
              company_reason_social: "",
              user_id: this.$store.getters.get_all_user.id,
              state: 0,
              department_id: "",
              province_id: "",
              district_id: "",
              // district:{
              //   department_id:"",
              //   id:"",
              //   name:"",
              //   province_id:""
              // }
            };

            bus.$emit("alert", {
              color: color,
              message: message,
            });
          }
        })
        .catch((errors) => {
          bus.$emit("alert", {
            color: "danger",
            message: errors,
          });
        });
    },
    mtd_show: function (id) {
      this.get({
        url:
          this.$store.getters.get__url + "/" + this.prefix + "/" + id + "/show",
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          this.campu = response;
          this.campu.department_id = response.district.department_id;
          this.province_id = response.district.province_id;
          this.district_id = response.district.id;
          this.mtd_open_modal(true, "update");
        })
        .catch((errors) => {});
    },
    mtd_delete: function (item) {
      this.modal_delete.boo = true;
      this.modal_delete.item = item;
    },
    close_delete: function () {
      this.modal_delete.boo = false;
    },
    mtd_commit: function () {
      this.post({
        url: this.$store.getters.get__url + "/" + this.prefix + "/destroy",
        token: this.$store.getters.get__token,
        params: {
          id: this.modal_delete.item.id,
        },
      })
        .then((response) => {
          /*
          let temp = [];
          this.data.forEach((element) => {
            if (element.id != response.data.id) {
              temp.push(element);
            }
          });
          this.data = temp;
          */
          this.mtd_getdata();
          this.modal_delete = {
            boo: false,
            item: [],
          };

          bus.$emit("alert", {
            color: "success",
            message: "ELIMINADO CORRECTAMENTE",
          });
        })
        .catch((errors) => {});
    },
    validatePhone() {
      if (this.campu.phone.length != 9 || isNaN(this.campu.phone)) {
        this.errors.phone =
          "El teléfono debe ser un número de máximo 9 dígitos";
      } else {
        this.errors.phone = "";
      }
    },
    validateForm() {
      this.validatePhone();

      return this.errors.phone === "";
    },
    changeSwitch: function (payload) {
      this.campu.state = payload == true ? 1 : 0;
    },
  },
  destroyed() {
    // console.log(this.dataStore);
    // this.$store.commit("mt_set_datacampus", this.dataStore);
  },
};
</script>

<style lang="scss" scoped>
.btn-search {
  color: #fff;
  background-color: #2819ae;
  border-color: #2517a3;
}
</style>
