<template>
  <CModal
    @mtdclosemodal="mtdClose"
    :show="boo_modal"
    :close-on-backdrop="false"
    :centered="true"
    :title="title"
    :size="'lg'"
    :btn_cancel="true"
    :btn_ok="true"
    :booTitile="true"
  >
    <div class="row">
      <div class="col-md-12 col-lg-12 col-xs-12">
        <div id="table-detalle">
          <div class="modal-body">
            <div class="modal-body mr-2 ml-2">
              <div class="row">
                <div class="col-sm-12 col-md-12 col-xl-4 mb-3">
                  <CInput
                    label="Fecha de Reserva"
                    type="date"
                    v-model="booking.reservation_date"
                    @input="updateReservationDay"
                  />
                </div>
                <div class="col-sm-12 col-md-12 col-xl-4 mb-3">
                  <cSelectForm
                    label="Campo Deportivo"
                    placeholder="Seleccione campo deportivo"
                    :options="data_sport_field"
                    :value.sync="booking.sport_field_id"
                    :disabled="!this.attention"
                    @change="mtdSelectCampo"
                  />
                </div>
                <div class="col-sm-12 col-md-12 col-xl-4 mb-3">
                  <cSelectForm
                    label="Deporte"
                    placeholder="Seleccione deporte"
                    :options="data_sport"
                    :value.sync="booking.sport_id"
                    :disabled="!booking.sport_field_id"
                    @change="mtdSelectSport"
                  />
                </div>
              </div>

              <div class="row">
                <div class="col-sm-12 col-md-12 col-xl-3 mb-3">
                  <CInput
                    label="Hora de Inicio"
                    type="time"
                    v-model="booking.start_time"
                    :disabled="!booking.sport_id"
                    @blur="determineShift"
                  />
                </div>
                <div class="col-sm-12 col-md-12 col-xl-3 mb-3">
                  <cSelectForm
                    label="Horas"
                    placeholder="Seleccione cant. de horas"
                    :options="data_hours"
                    :value.sync="booking.hours"
                    :disabled="!booking.start_time"
                  />
                </div>
                <div class="col-sm-12 col-md-12 col-xl-3 mb-3">
                  <CInput
                    label="Hora Final"
                    type="time"
                    v-model="booking.end_time"
                    disabled
                  />
                </div>
                <div class="col-sm-12 col-md-12 col-xl-3 mb-3">
                  <CInput label="Precio" v-model="booking.total_pay" disabled />
                </div>
              </div>
              <div class="row">
                <div class="col-sm-12 col-md-12 col-xl-4 mb-3">
                  <CInput
                    label="Dni"
                    placeholder="Digite dni"
                    v-model="booking.sport_man_dni"
                    maxlength="8"
                    @keyup="mtdSearchDocument"
                  />
                </div>
                <!-- <div class="col-sm-12 col-md-12 col-xl-4 mb-3 my-auto">
                  <div class="mx-2 my-auto mt-2" @click="mtdSearchDocument">
                    <button
                      type="button"
                      class="btn btn-outline-main bg-purple color-white"
                    >
                      <i class=""></i> Buscar
                    </button>
                  </div>
                </div> -->
              </div>
              <div class="row">
                <div class="col-sm-12 col-md-12 col-xl-4 mb-3">
                  <CInput
                    label="Cliente"
                    v-model="booking.name"
                    :disabled="booking.existingSportman"
                  />
                </div>
                <div class="col-sm-12 col-md-12 col-xl-4 mb-3">
                  <CInput
                    label="Telefono"
                    v-model="booking.phone"
                    maxlength="9"
                    :disabled="!booking.name"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <template #footer>
      <button @click="mtdClose" class="btn btn-danger btn-sm">
        <i class="fa fa-times" />&nbsp;Cerrar
      </button>
      <button
        @click="mtdUpdateBooking"
        class="btn btn-success btn-sm"
        :disabled="!canSave"
      >
        Actualizar
      </button>
      &nbsp;
    </template>
  </CModal>
</template>

<script>
import CModal from "../../shared/modals/cModalFormCom.vue";
import { mapActions } from "vuex";
import cSelectForm from "../../shared/inputs/cSelect.vue";
import moment from "moment";

// import SweetAlert from '@sweetalert2/vue';

export default {
  name: "c-updatereserva-modal",
  components: {
    CModal,
    cSelectForm,
    // SweetAlert,
  },
  data() {
    return {
      swal: null,
      flag_select_campo: 0,
      flag_select_start_time: 0,
      data_sport_field: [],
      data_sport: [],
      data_rates: [],
      booking: {
        id: this.$props.id,
        employee_id: "",
        sport_field_id: "",
        rate_id: "",
        sport_field_name: "",
        sport_id: "",
        sport_name: "",
        sport_fieldxsport_id: "",
        reservation_date: "",
        reservation_day: "",
        start_time: "",
        hours: "",
        end_time: "",
        name: "",
        phone: "",
        subtotal_pay: "",
        total_pay: "",
        sport_man_id: "",
        // sport_man_name: "",
        // sport_man_phone: "",
        sport_man_dni: "",
        sport_man_address: "",
        existingSportman: true,
        shift: "",
      },
      schedule: {
        id: "",
        start_time: "",
        end_time: "",
        shift: "",
      },
      attention: false,
      shifts: {
        morning: { start: "06:00", end: "11:59" },
        afternoon: { start: "12:00", end: "17:59" },
        night: { start: "18:00", end: "23:59" },
      },
      data_hours: [
        { value: "00:30", label: "30 min" },
        { value: "01:00", label: "1 Hora" },
      ],
    };
  },
  props: {
    title: String,
    boo_modal: Boolean,
    id: Number,
  },
  created() {
    //this.mtdSelectSport();
  },
  watch: {
    boo_modal(newVal) {
      if (newVal) {
        this.get_employeeid();
        this.mtd_show();
        this.indexRate();
        // this.indexSport_Field();
      }
    },
    bookingTrigger(newVal) {
      if (newVal) {
        // this.indexRate();
        this.determineShift();
        this.showPrice();
      }
    },
    "booking.start_time": function (newId) {
      if (newId) {
        // this.determineShift();
      }
    },
    "booking.hours": function (newId) {
      if (newId) {
        this.calculateEndTime();
      }
    },
  },
  computed: {
    canSave() {
      return (
        this.booking.reservation_date !== "" &&
        this.booking.sport_field_id !== "" &&
        this.booking.sport_id !== "" &&
        this.booking.start_time !== "" &&
        this.booking.hours !== "" &&
        this.booking.end_time !== "" &&
        this.booking.sport_man_id !== ""
      );
    },
    bookingTrigger() {
      return this.booking.sport_field_id + this.booking.sport_id;
    },
  },

  methods: {
    ...mapActions(["get", "post"]),
    mtdClose() {
      ((this.booking = {
        reservation_date: "",
      }),
      (this.flag_select_campo = 0),
      (this.flag_select_start_time = 0)),
        this.$emit("closeModalUpdateBooking");
    },
    formatDate(date) {
      const [day, month, year] = date.split("/");
      return `${year}-${month}-${day}`;
    },
    formatToOriginalDate(date) {
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    // indexSport_Field: function () {
    //   this.get({
    //     url:
    //       this.$store.getters.get__url +
    //       "/bookings/sport_field/" +
    //       this.$store.getters.get_campu.value,
    //     token: this.$store.getters.get__token,
    //   })
    //     .then((response) => {
    //       this.data_sport_field=[];
    //       response.data.sports_fields.forEach((element) => {
    //         this.data_sport_field.push({
    //           label: element.name,
    //           value: element.id,
    //           detail: element,
    //         });
    //         this.mtdSelectCampo();
    //       });
    //     })
    //     .catch((errors) => {});
    // },
    indexRate: function () {
      this.get({
        url:
          this.$store.getters.get__url +
          "/bookings/rate/" +
          this.$store.getters.get_campu.value,
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          this.data_rates = [];
          response.data.rates.forEach((element) => {
            this.data_rates.push({
              value: element.id,
              shift: element.shift,
              price: element.price,
              sport_fieldxsport_id: element.sport_fieldsxsport_id,
              detail: element,
            });
          });
          this.bySport_Field();
        })
        .catch((errors) => {});
    },
    bySport_Field: function () {
      this.data_sport_field = [];
      const addedFields = new Set(); // Para almacenar los IDs de campos deportivos agregados

      this.data_rates.forEach((arr) => {
        const sportFieldId = arr.detail.sport_fieldxsport.sport_field_id;
        if (!addedFields.has(sportFieldId)) {
          // Verificar si el campo ya fue agregado
          addedFields.add(sportFieldId);
          const { id, name } = arr.detail.sport_fieldxsport.sport_field;
          this.data_sport_field.push({
            label: name,
            value: id,
          });
        }
      });
      this.mtdSelectCampo();
    },
    /* select campo*/
    mtdSelectCampo: function () {
      this.data_sport = [];
      let sport_id = -1;
      if (this.flag_select_campo != 0) {
        // this.booking.sport_id="";
        this.booking.hours = "";
        this.booking.end_time = "";
      }
      this.data_rates.forEach((arr) => {
        if (
          arr.detail.sport_fieldxsport.sport_field_id ==
          this.booking.sport_field_id
        ) {
          if (arr.detail.sport_fieldxsport.sport.id != sport_id) {
            sport_id = arr.detail.sport_fieldxsport.sport.id;
            const { id, name } = arr.detail.sport_fieldxsport.sport;
            this.data_sport.push({
              label: name,
              value: id,
            });
          }
        }
      });
      this.flag_select_campo++;
      this.mtdSelectSport();
    },
    /*select sport y guardo el id de sport_fieldxsport en this.booking*/
    mtdSelectSport: function () {
      this.data_rates.forEach((arr) => {
        if (
          arr.detail.sport_fieldxsport.sport_field_id ==
            this.booking.sport_field_id &&
          arr.detail.sport_fieldxsport.sport_id == this.booking.sport_id
        ) {
          this.booking.sport_fieldxsport_id = arr.detail.sport_fieldxsport.id;
        }
      });
      // if(this.booking.hours){
      //   this.calculateEndTime();
      // }
    },
    mtd_show: function () {
      this.get({
        url:
          this.$store.getters.get__url +
          "/bookings/" +
          this.$props.id +
          "/show",
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          this.booking = response;
          this.booking.sport_fieldxsport_id =
            response.rate.sport_fieldsxsport_id;
          this.booking.sport_field_id =
            response.rate.sport_fieldxsport.sport_field_id;
          this.booking.sport_field_name =
            response.rate.sport_fieldxsport.sport_field.name;
          this.booking.sport_id = response.rate.sport_fieldxsport.sport_id;
          this.booking.sport_name = response.rate.sport_fieldxsport.sport.name;
          this.booking.reservation_date = this.booking.reservation_date;
          this.booking.sport_man_id = response.sportsman_id;
          this.booking.existingSportman = true;
          this.updateReservationDay();
        })
        .catch((errors) => {});
    },
    updateReservationDay() {
      if (this.booking.reservation_date) {
        const date = new Date(this.booking.reservation_date);
        const days = [
          "DOMINGO",
          "LUNES",
          "MARTES",
          "MIERCOLES",
          "JUEVES",
          "VIERNES",
          "SABADO",
        ];
        this.booking.reservation_day = days[date.getUTCDay()];
      }
      this.showTime();
    },

    //Selecciona día y que me cargue el horario de la sede
    showTime: function () {
      this.get({
        url:
          this.$store.getters.get__url +
          "/bookings/campu/" +
          this.$store.getters.get_campu.value +
          "/" +
          this.booking.reservation_day,
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          if (response.data.state == 1) {
            Swal.fire({
              title: "No hay atención",
              icon: "warning",
            });
            this.attention = false;
          } else {
            // this.booking.reservation_date = this.quote.dateStr;
            this.attention = true;
            response.data.data.forEach((element) => {
              this.schedule.start_time = element.start_time;
              this.schedule.end_time = element.end_time;
            });
          }
        })
        .catch((errors) => {});
    },
    determineShift: function () {
      if (this.flag_select_start_time != 0) {
        this.booking.hours = "";
        this.booking.end_time = "";
      }
      const startTime = moment(this.booking.start_time, "HH:mm");

      const { morning, afternoon, night } = this.shifts;

      if (
        startTime.isBetween(
          moment(morning.start, "HH:mm"),
          moment(morning.end, "HH:mm"),
          null,
          "[)"
        )
      ) {
        this.schedule.shift = "Dia";
        this.booking.shift = "Dia";
      } else if (
        startTime.isBetween(
          moment(afternoon.start, "HH:mm"),
          moment(afternoon.end, "HH:mm"),
          null,
          "[)"
        )
      ) {
        this.schedule.shift = "Tarde";
        this.booking.shift = "Tarde";
      } else if (
        startTime.isBetween(
          moment(night.start, "HH:mm"),
          moment(night.end, "HH:mm"),
          null,
          "[)"
        )
      ) {
        this.schedule.shift = "Noche";
        this.booking.shift = "Noche";
      }
      this.flag_select_start_time++;
      if (this.schedule.start_time) {
        this.validateHoursInicio();
      }
    },
    validateHoursInicio: function () {
      const formattedTime = this.formatTime(this.schedule.start_time);

      if (this.booking.start_time < formattedTime) {
        Swal.fire({
          title: "La sede abre a las " + formattedTime,
          icon: "warning",
        });
        this.booking.start_time = "";
      }
    },
    formatTime: function (timeString) {
      // Dividir la cadena de tiempo en horas, minutos y segundos
      const [hours, minutes] = timeString.split(":");
      // Formatear la hora a "HH:MM"
      return `${hours}:${minutes}`;
    },
    calculateEndTime: function () {
      const [hours1, minutes1] = this.booking.start_time.split(":").map(Number);
      const totalMinutes1 = hours1 * 60 + minutes1;

      // const [hours2, minutes2] = event.target.value.split(":").map(Number);
      const [hours2, minutes2] = this.booking.hours.split(":").map(Number);
      const totalMinutes2 = hours2 * 60 + minutes2;

      // Sumar los minutos
      const totalMinutes = totalMinutes1 + totalMinutes2;

      // Convertir de nuevo a horas y minutos
      const resultHours = Math.floor(totalMinutes / 60);
      const resultMinutes = totalMinutes % 60;

      // Formatear el resultado para que siempre tenga dos dígitos en los minutos
      const formattedHours = resultHours.toString().padStart(2, "0");
      const formattedMinutes = resultMinutes.toString().padStart(2, "0");

      this.booking.end_time = `${formattedHours}:${formattedMinutes}`;
      this.showPrice();
    },
    showPrice: function () {
      let date = this.formatToOriginalDate(this.booking.reservation_date);
      this.get({
        url:
          this.$store.getters.get__url +
          "/bookings/priceUpdate/" +
          this.booking.id +
          "/" +
          date +
          "/" +
          this.booking.start_time +
          "/" +
          this.booking.end_time +
          "/" +
          this.booking.reservation_day +
          "/" +
          this.booking.shift +
          "/" +
          this.booking.sport_fieldxsport_id,
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          if (response.data.state == 1) {
            Swal.fire({
              title: "Canchita Ocupada",
              text:
                response.data.data.start_time +
                " hasta " +
                response.data.data.end_time,
              icon: "warning",
            });
          } else if (response.data.state == 2) {
            Swal.fire({
              title: "Agregue Tarifa",
              icon: "warning",
            });
          } else {
            response.data.data.forEach((element) => {
              this.booking.rate_id = element.id;
              this.booking.subtotal_pay = element.price;
            });
            let subtotalPay = parseFloat(this.booking.subtotal_pay);
            if (this.booking.hours === "00:30") {
              this.booking.total_pay = (subtotalPay / 2).toFixed(2);
            } else if (this.booking.hours === "01:00") {
              this.booking.total_pay = (subtotalPay * 1).toFixed(2);
            }
          }
        })
        .catch((errors) => {});
    },
    validateDNI: function (dni) {
      const dniPattern = /^[0-9]{8}$/;
      return dniPattern.test(dni);
    },
    get_employeeid: function () {
      this.get({
        url:
          this.$store.getters.get__url +
          "/bookings/employee/" +
          this.$store.getters.get__user_id,
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          response.data.data.forEach((element) => {
            this.booking.employee_id = element.id;
          });
        })
        .catch((errors) => {});
    },
    // filtrarSportMan: function () {
    //   if (!this.validateDNI(this.booking.sport_man_dni)) {
    //     Swal.fire({
    //       title: "DNI inválido",
    //       text: "El DNI debe tener exactamente 8 dígitos y no debe contener letras.",
    //       icon: "warning",
    //     });
    //     return;
    //   }
    //   this.get({
    //     url:
    //       this.$store.getters.get__url +
    //       "/bookings/sportman/" +
    //       this.booking.sport_man_dni,
    //     token: this.$store.getters.get__token,
    //   })
    //     .then((response) => {
    //       if (response.data.state == 1) {
    //         Swal.fire({
    //           title: "No se encontró DNI",
    //           icon: "warning",
    //         });
    //         this.booking.existingSportman = false;
    //         this.booking.name = ""; // Clear the name field
    //         this.booking.phone = ""; // Clear the phone field
    //       } else {
    //         response.data.data.forEach((element) => {
    //           this.booking.name = element.name;
    //           this.booking.phone = element.phone;
    //           this.booking.sport_man_id = element.id;
    //           this.booking.existingSportman = true;
    //         });
    //       }
    //     })
    //     .catch((errors) => {});
    // },
    filtrarPhone: function () {
      this.get({
        url:
          this.$store.getters.get__url +
          "/bookings/sportman/" +
          this.booking.sport_man_dni,
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          if (response.data.state == 1) {
            this.booking.phone = "";
            this.booking.sport_man_address = "";
          } else {
            response.data.data.forEach((element) => {
              this.booking.phone = element.phone;
              this.booking.sport_man_id = element.id;
            });
          }
        })
        .catch((errors) => {});
    },
    mtdUpdateBooking() {
      if (
        this.booking.reservation_date &&
        this.booking.employee_id &&
        this.booking.name
      ) {
        //this.booking.reservation_date = this.formatToOriginalDate(this.booking.reservation_date);
        this.flag_select_campo = 0;
        this.flag_select_start_time = 0;
        this.post({
          url: this.$store.getters.get__url + "/bookings/update",
          token: this.$store.getters.get__token,
          params: this.booking,
        })
          .then((response) => {
            if (response.data.state == 2) {
              Swal.fire({
                title: "Agregue Tarifa",
                icon: "warning",
              });
            } else {
              let color = "";
              let message = "";
              if (response.statusCode == 200) {
                color = "success";
                message = "REGISTRADO CORRECTAMENTE";
              } else {
                color = "danger";
                message = "error";
              }
              Swal.fire({
                position: "center",
                icon: "success",
                title: "Reserva Editada",
                showConfirmButton: false,
                timer: 1500,
              });
              this.resetFormData();
              this.$emit("UpdateBooking");
              this.$emit("closeModalUpdateBooking");
            }
          })
          .catch((errors) => {
            bus.$emit("alert", {
              color: "danger",
              message: errors,
            });
          });
      } else {
        Swal.fire({
          icon: "error",
          title: "Complete todos los campos!",
        });
      }
    },
    resetFormData() {
      this.booking = {
        id: "",
        employee_id: "",
        rate_id: "",
        sport_field_id: "",
        sport_field_name: "",
        sport_id: "",
        sport_name: "",
        sport_fieldxsport_id: "",
        reservation_date: "",
        reservation_day: "",
        start_time: "",
        hours: "",
        end_time: "",
        name: "",
        phone: "",
        subtotal_pay: "",
        total_pay: "",
        sport_man_id: "",
        sport_man_name: "",
        sport_man_phone: "",
        sport_man_dni: "",
        sport_man_address: "",
        existingSportman: true,
      };
    },
    mtdSearchDocument: function () {
      if (this.booking.sport_man_dni.length == 8) {
        this.get({
          url:
            this.$store.getters.get__url +
            "/Consultas/" +
            this.booking.sport_man_dni,
          token: this.$store.getters.get__token,
        }).then((response) => {
          if (response.data.boo == 3) {
            Swal.fire({
              title: "DNI no encontrado",
              icon: "warning",
            });
          } else {
            this.booking.name =
              response.data.name + " " + response.data.last_name;
            this.booking.sport_man_address = response.data.direccion;
            this.filtrarPhone();
          }
        });
      }
    },
  },
};
</script>
<style scoped>
.bg-purple {
  background-color: #926cdc;
}
.color-white {
  color: white;
  /* display: flex;
    flex-wrap: wrap; */
}
</style>
