<template>
  <div class="container">
    <CRow class="mt-3">
      <CCol xxl="12" xl="12" md="12" sm="12" class="text-center">
        <h4>¿EN QUÉ EMPRESA DESEA TRABAJAR?</h4>
        <p>{{ userName }}</p>
      </CCol>
    </CRow>
    <CRow class="mt-3">
      <CCol xxl="3" xl="3" md="3" sm="12"></CCol>
      <CCol xxl="6" xl="6" md="6" sm="12" class="text-center">
        <div  class="d-flex justify-content-around">
          <CCard
            v-for="(item, index) in companies"
            :key="index"
            class="mr-1 card"
            @click="mtdSetCompany(item)"
            color="gradient-info"
            body-wrapper
            text-color="white"
          >
            {{ item.reason_social }}
          </CCard>
        </div>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "c-home-companies",

  data(){
    return{
      // data_companies:[],
    }
  },
  
  props: {
    companies: {
      type: Array,
      default: [],
    },
  },
  computed: {
    userName() {
      return this.$store.state.user.name;
    },
    userRoles() {
      return this.$store.getters.get_all_user;
    },
    // isAdminGeneral() {
    //   const rol = this.$store.getters.get_all_user.roles[0].role.name;
    //   return rol === 'ADMINISTRADOR GENERAL';
    // },
    // isRecepcionista() {
    //   const rol = this.$store.getters.get_all_user.roles[0].role.name;
    //   return rol === 'RECEPCIONISTA';
    // },
  },
  created() {
  },
  methods: {
    ...mapActions(["get", "post"]),
    // mtdSetCampu: function (payload) {
    //   this.$emit("mtdSetCampu", payload);
    // },
    mtdSetCompany: function (payload){
      this.$emit("mtdSetCompany", payload);
    }
  },
};
</script>

<style scoped>
.card {
  cursor: pointer;
}
</style>
