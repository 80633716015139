<template>
  <div class="container mx-auto">
    <CRow>
      <CCol xs="12" sm="12" md="3" lg="3" xxl="3">
        <CCard>
          <CCardHeader class="d-flex justify-content-between">
            <b><i class="fas fa-calendar"></i>&nbsp;Reservas</b>
            <button
              type="button"
              class="btn btn-sm btn-outline-main bg-purple color-white"
              @click="mtdBack"
            >
              <i class="fas fa-home-lg-alt"></i>&nbsp;&nbsp;Inicio
            </button>
          </CCardHeader>
          <CCardBody>
            <CRow>
              <CCol xs="12" sm="12" md="12" lg="12" xxl="12">
                <CInput
                  label="Desde"
                  type="date"
                  @input="applyDateFilter"
                  aria-label="shift_date"
                  size="sm"
                  id="init"
                  v-model="filter.init"
                />
              </CCol>
              <CCol xs="12" sm="12" md="12" lg="12" xxl="12">
                <CInput
                  label="Hasta"
                  type="date"
                  @input="applyDateFilter"
                  aria-label="shift_date"
                  id="end"
                  v-model="filter.end"
                  size="sm"
                />
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      </CCol>
      <CCol xs="12" sm="12" md="9" lg="9" xxl="9">
        <div class="row">
          <!-- Reservado Section -->
          <div
            class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-xs-12 mb-2 mb-2"
          >
            <div class="bg-card p-4 rounded-lg shadow mb-4">
              <h3 class="text-lg font-semibold mb-2">Reservado</h3>
              <input
                type="text"
                placeholder="Buscar"
                class="form-control form-control-sm mb-4"
                v-model="searchReservado"
                @input="filterReservado"
              />
              <draggable
                :group="{ name: 'states' }"
                @end="onDragReservado"
                class="list-group"
                id="reservado"
              >
                <div
                  v-for="item in filteredReservado"
                  :key="item.id"
                  class="text-dark"
                >
                  <CCard color="">
                    <CCardHeader>
                      {{ item.name }}
                      <div class="card-header-actions">
                        <CLink
                          class="card-header-action btn-minimize text-info"
                          @click="mtdSelectCardReserva(item.id)"
                        >
                          <i
                            v-if="isCollapsed == item.id"
                            class="fas fa-chevron-up"
                          ></i>
                          <i v-else class="fas fa-chevron-down"></i>
                        </CLink>
                        <CLink
                          href="javascript:void(0)"
                          class="card-header-action btn-close text-success"
                          @click="mtdUpdateBooking(item.id)"
                        >
                          <i class="fas fa-edit"></i>
                        </CLink>
                        <CLink
                          href="javascript:void(0)"
                          class="card-header-action btn-close text-danger"
                          @click="mtd_delete(item)"
                        >
                          <i class="fas fa-trash"></i>
                        </CLink>
                      </div>
                    </CCardHeader>
                    <CCollapse :show="isCollapsed == item.id" :duration="400">
                      <CCardBody>
                        <span
                          >{{
                            item.rate.sport_fieldxsport.sport_field.name +
                            ", " +
                            item.rate.sport_fieldxsport.sport.name
                          }}
                        </span>
                        <button 
                          :class="[getPayment(item.payment) != 'Pagado' ?  'btn btn-info btn-sm' : 'btn btn-success btn-sm']"
                          @click="mtdPaymentBooking(item)"
                          :disabled="getPayment(item.payment)== 'Pagado'"
                        >
                          {{ getPayment(item.payment) }}
                        </button>
                        <br />
                        <span
                          >{{ formatTime(item.start_time) }} -
                          {{ formatTime(item.end_time) }}</span
                        >
                      </CCardBody>
                    </CCollapse>
                  </CCard>
                </div>
              </draggable>
            </div>
          </div>
          <!-- En curso Section -->
          <div
            class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-xs-12 mb-2 mb-2"
          >
            <div class="bg-card p-4 rounded-lg shadow mb-4">
              <h3 class="text-lg font-semibold mb-2">En curso</h3>
              <input
                type="text"
                placeholder="Buscar"
                class="form-control mb-4 form-control-sm"
                v-model="searchEnCurso"
                @input="filterEnCurso"
              />
              <draggable
                :group="{ name: 'states' }"
                class="list-group"
                @end="onDragEncurso"
                id="encurso"
              >
                <div
                  v-for="item in filteredEnCurso"
                  :key="item.id"
                  class="text-dark"
                >
                  <CCard color="">
                    <CCardHeader>
                      {{ item.name }}
                      <div class="card-header-actions">
                        <CLink
                          class="card-header-action btn-minimize text-info"
                          @click="mtdSelectCardReserva(item.id)"
                        >
                          <i
                            v-if="isCollapsed == item.id"
                            class="fas fa-chevron-up"
                          ></i>
                          <i v-else class="fas fa-chevron-down"></i>
                        </CLink>
                        <!-- <CLink
                          href="javascript:void(0)"
                          class="card-header-action btn-close text-success"
                          @click="mtdUpdateBooking(item.id)"
                        >
                          <i class="fas fa-edit"></i>
                        </CLink>
                        <CLink
                          href="javascript:void(0)"
                          class="card-header-action btn-close text-danger"
                        >
                          <i class="fas fa-trash"></i>
                        </CLink> -->
                      </div>
                    </CCardHeader>
                    <CCollapse :show="isCollapsed == item.id" :duration="400">
                      <!-- {{ getPayment(item.payment) }} -->
                      <CCardBody>
                        <span
                          >{{
                            item.rate.sport_fieldxsport.sport_field.name +
                            ", " +
                            item.rate.sport_fieldxsport.sport.name
                          }}
                        </span>
                        <!-- <button class="btn btn-info btn-sm">
                          {{ getPayment(item.payment) }}
                        </button> -->
                        <button 
                          :class="[getPayment(item.payment) != 'Pagado' ?  'btn btn-info btn-sm' : 'btn btn-success btn-sm']"
                          @click="mtdPaymentBooking(item)"
                          :disabled="getPayment(item.payment)== 'Pagado'"
                        >
                          {{ getPayment(item.payment) }}
                        </button>
                        <br />
                        <span
                          >{{ formatTime(item.start_time) }} -
                          {{ formatTime(item.end_time) }}</span
                        >
                      </CCardBody>
                    </CCollapse>
                  </CCard>
                </div>
              </draggable>
            </div>
          </div>
          <!-- Terminado Section -->
          <div
            class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-xs-12 mb-2 mb-2"
          >
            <div class="bg-card p-4 rounded-lg shadow mb-4">
              <h3 class="text-lg font-semibold mb-2">Terminado</h3>
              <input
                type="text"
                placeholder="Buscar"
                class="form-control mb-4 form-control-sm"
                v-model="searchTerminado"
                @input="filterTerminado"
              />
              <draggable
                :group="{ name: 'states', pull: false }"
                class="list-group"
                @end="onDragTerminado"
                id="terminado"
              >
                <div
                  v-for="item in filteredTerminado"
                  :key="item.id"
                  class="text-dark"
                >
                <CCard color="">
                    <CCardHeader>
                      {{ item.name }}
                      <div class="card-header-actions">
                        <CLink
                          class="card-header-action btn-minimize text-info"
                          @click="mtdSelectCardReserva(item.id)"
                        >
                          <i
                            v-if="isCollapsed == item.id"
                            class="fas fa-chevron-up"
                          ></i>
                          <i v-else class="fas fa-chevron-down"></i>
                        </CLink>
                        <!-- <CLink
                          href="javascript:void(0)"
                          class="card-header-action btn-close text-success"
                          @click="mtdUpdateBooking(item.id)"
                        >
                          <i class="fas fa-edit"></i>
                        </CLink>
                        <CLink
                          href="javascript:void(0)"
                          class="card-header-action btn-close text-danger"
                        >
                          <i class="fas fa-trash"></i>
                        </CLink> -->
                      </div>
                    </CCardHeader>
                    <CCollapse :show="isCollapsed == item.id" :duration="400">
                      <!-- {{ getPayment(item.payment) }} -->
                      <CCardBody>
                        <span
                          >{{
                            item.rate.sport_fieldxsport.sport_field.name +
                            ", " +
                            item.rate.sport_fieldxsport.sport.name
                          }}
                        </span>
                        <!-- <button class="btn btn-info btn-sm">
                          {{ getPayment(item.payment) }}
                        </button> -->
                        <button 
                          :class="[getPayment(item.payment) != 'Pagado' ?  'btn btn-info btn-sm' : 'btn btn-success btn-sm']"
                          @click="mtdPaymentBooking(item)"
                          :disabled="getPayment(item.payment)== 'Pagado'"
                        >
                          {{ getPayment(item.payment) }}
                        </button>
                        <br />
                        <span
                          >{{ formatTime(item.start_time) }} -
                          {{ formatTime(item.end_time) }}</span
                        >
                      </CCardBody>
                    </CCollapse>
                  </CCard>
                </div>
              </draggable>
            </div>
          </div>
        </div>
      </CCol>
    </CRow>

    <cModalUpdateBookingVue
      :title="modalUpdateBooking.title"
      :boo_modal="modalUpdateBooking.modal_form"
      :id="modalUpdateBooking.id_reserva"
      @closeModalUpdateBooking="closeModalUpdateBooking"
    />
    <cModalPaymentVue
      :title="modalPaymentBooking.title"
      :boo_modal="modalPaymentBooking.modal_form"
      :id="modalPaymentBooking.id_reserva"
      :total_pay="modalPaymentBooking.total_pay"
      :id_sale="modalPaymentBooking.id_sale"
      :item="modalPaymentBooking.item"
      @closeModalPaymentBooking="closeModalPaymentBooking"
    />
    <cModalDelete
      :backdrop="true"
      :closeOnBackdrop="true"
      :size="'md'"
      :title="'Borrar Reserva'"
      :show.sync="modal_delete.boo"
      @mtd_commit="mtd_commit"
      :centered="true"
      :item="modal_delete.item"
    />
  </div>
</template>


<script>
import draggable from "vuedraggable";
import cModalDelete from "../../shared/modals/cModalDelete.vue";
import moment from "moment";
import { mapActions } from "vuex";
import { bus } from "../../../main";
import cModalUpdateBookingVue from "../modals/cModalUpdateBooking.vue";
import cModalPaymentVue from "../modals/cModalPayment.vue";

export default {
  components: {
    cModalDelete,
    draggable,
    cModalUpdateBookingVue,
    cModalPaymentVue,
  },
  data() {
    return {
      prefix: "bookings",
      data: [],
      searchReservado: "",
      searchEnCurso: "",
      searchTerminado: "",
      reservado: [],
      encurso: [],
      terminado: [],
      filteredReservado: [],
      filteredEnCurso: [],
      filteredTerminado: [],
      modalUpdateBooking: {
        title: "EDITAR RESERVA",
        item: [],
        modal_form: false,
        pos: null,
        id_reserva: 0,
      },
      modalPaymentBooking:{
        title:"PAGAR RESERVA",
        modal_form: false,
        id_reserva:0,
        total_pay:"",
        id_sale:0,
        item:{},
      },
      modal_delete: {
        boo: false,
        item: [],
      },
      startDate: "",
      endDate: "",
      booking: {
        id: "",
        state: "",
      },
      filter: {
        init: null,
        end: null,
      },
      /** accordion */
      show: null,
      isCollapsed: null,
    };
  },
  created() {
    moment.locale("es");
    this.filter.end =
      moment().format("L").substr(6, 4) +
      "-" +
      moment().format("L").substr(3, 2) +
      "-" +
      moment().format("L").substr(0, 2);
    this.filter.init = this.filter.end;
    this.applyDateFilter();
  },
  methods: {
    ...mapActions(["get", "post"]),
    mtdUpdateBooking: function (item) {
      this.modalUpdateBooking = {
        title: "EDITAR RESERVA",
        modal_form: true,
        id_reserva: item,
      };
    },
    mtdPaymentBooking: function (item) {
      this.modalPaymentBooking = {
        title: "PAGAR RESERVA",
        modal_form: true,
        id_reserva: item.id,
        total_pay: item.total_pay,
        id_sale: item.sale_id,
        item: item,
      };
    },
    closeModalUpdateBooking: function () {
      this.modalUpdateBooking = {
        item: [],
        modal_form: false,
        pos: null,
      };
      this.applyDateFilter();
    },
    closeModalPaymentBooking: function (){
      this.modalPaymentBooking ={
        modal_form: false,
      }
      this.applyDateFilter();

    },
    //PARA EL ESTADO
    onDragReservado(event) {
      const draggedIndex = event.oldIndex;
      const draggedItem = this.reservado[draggedIndex];
      const idAsign = draggedItem.id;
      let status;
      if (event.to.id === "reservado") {
        status = 1;
      } else if (event.to.id === "encurso") {
        status = 2;
      } else if (event.to.id === "terminado") {
        status = 3;
      }
      if (status != 1) {
        this.updateItemState(idAsign, status);
      }
    },

    onDragEncurso(event) {
      const draggedIndex = event.oldIndex;
      const draggedItem = this.encurso[draggedIndex];
      const idAsign = draggedItem.id;
      let status;
      if (event.to.id === "reservado") {
        status = 1;
      } else if (event.to.id === "encurso") {
        status = 2;
      } else if (event.to.id === "terminado") {
        status = 3;
      }

      if (status != 2) {
        this.updateItemState(idAsign, status);
      }
    },

    onDragTerminado(event) {
      const draggedIndex = event.oldIndex;
      const draggedItem = this.terminado[draggedIndex];
      const idAsign = draggedItem.id;
      let status;
      if (event.to.id === "reservado") {
        status = 1;
      } else if (event.to.id === "encurso") {
        status = 2;
      } else if (event.to.id === "terminado") {
        status = 3;
      }

      if (status != 3) {
        this.updateItemState(idAsign, status);
      }
    },
    updateItemState(idAsign, status) {
      this.post({
        url:
          this.$store.getters.get__url + "/" + this.prefix + "/updateItemState",
        token: this.$store.getters.get__token,
        params: {
          idAsign: idAsign,
          status: status,
        },
      })
        .then((response) => {
          if (response.data.state == 0) {
            this.applyDateFilter();
          }
        })
        .catch((errors) => {
          this.$refs.SweetAlert.showError(errors);
        });
    },
    categorizeData() {
      this.reservado = this.data.filter((item) => item.state === 1);
      this.encurso = this.data.filter((item) => item.state === 2);
      this.terminado = this.data.filter((item) => item.state === 3);
      this.filteredReservado = this.reservado;
      this.filteredEnCurso = this.encurso;
      this.filteredTerminado = this.terminado;
      // this.filterByCurrentDate();
    },
    applyDateFilter() {
      this.post({
        url: this.$store.getters.get__url + "/" + this.prefix + "/dragable",
        token: this.$store.getters.get__token,
        params: {
          init: this.filter.init,
          end: this.filter.end,
          campu_id: this.$store.getters.get_campu.value,
        },
      })
        .then((response) => {
          this.data = response.data.data;
          this.categorizeData();
          // this.filterData(startDate, endDate);
        })
        .catch((errors) => {
          bus.$emit("alert", {
            color: "danger",
            message: errors,
          });
        });
    },
    filterReservado() {
      this.filteredReservado = this.reservado.filter((item) =>
        item.name.toLowerCase().includes(this.searchReservado.toLowerCase())
      );
    },
    filterEnCurso() {
      this.filteredEnCurso = this.encurso.filter((item) =>
        item.name.toLowerCase().includes(this.searchEnCurso.toLowerCase())
      );
    },
    filterTerminado() {
      this.filteredTerminado = this.terminado.filter((item) =>
        item.name.toLowerCase().includes(this.searchTerminado.toLowerCase())
      );
    },
    getStateText(state) {
      switch (state) {
        case 0:
          return "Cancelado";
        case 1:
          return "Reservada";
        case 2:
          return "En curso";
        case 3:
          return "Terminado";
        default:
          return "Reservado";
      }
    },
    getPayment(payment) {
      switch (payment) {
        case 0:
          return "Sin Pagar";
        case 1:
          return "Parcialmente Pagado";
        case 2:
          return "Pagado";
        default:
          return "Pendiente";
      }
    },
    formatTime(time) {
      const [hour, minute] = time.split(":");
      return `${hour}:${minute}`;
    },
    mtdBack() {
      this.$emit("setView", "home");
    },
    mtd_delete(item) {
      this.modal_delete.boo = true;
      this.modal_delete.item = item;
    },
    close_delete() {
      this.modal_delete.boo = false;
    },
    mtd_commit() {
      this.post({
        url: this.$store.getters.get__url + "/" + this.prefix + "/destroy",
        token: this.$store.getters.get__token,
        params: {
          id: this.modal_delete.item.id,
        },
      })
        .then((response) => {
          this.data = this.data.filter(
            (element) => element.id != response.data.id
          );

          this.categorizeData();

          this.modal_delete = {
            boo: false,
            item: [],
          };
          bus.$emit("alert", {
            color: "success",
            message: "ELIMINADO CORRECTAMENTE",
          });
        })
        .catch((errors) => {
          bus.$emit("alert", {
            color: "danger",
            message: "ERROR AL ELIMINAR",
          });
        });
    },
    /*** news */
    mtdSelectCardReserva: function (id) {
      if (id == this.isCollapsed) {
        this.isCollapsed = null;
      } else {
        this.isCollapsed = id;
      }
    },
  },
};
</script>



<style scoped>
.bg-purple {
  background-color: #926cdc;
}

.bg-white {
  background-color: white;
}

.color-white {
  color: white;
}

.color-black {
  color: black;
}

.bg-card {
  background-color: #926cdc;
}

.lista {
  cursor: pointer;
}

.grid-layout {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto auto auto;
  gap: 10px;
}

.grid-item {
  padding: 10px;
  border: 1px solid #ddd;
  display: flex;
  align-items: center;
  justify-content: center;
}

.client-name {
  grid-column: span 2;
  text-align: center;
}

.sports-info {
  grid-column: 1;
}

.reservation-time {
  grid-column: 2;
}

.payment-status {
  grid-column: 1;
}

.delete-btn {
  grid-column: 2;
}

.bg-encurso {
  background: #ede7f9;
}

.bg-terminado {
  background: #edeaec;
}

@media (max-width: 576px) {
  .col-lg-4 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .grid-layout {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto auto auto;
  }

  .client-name,
  .sports-info,
  .reservation-time,
  .payment-status,
  .delete-btn {
    grid-column: span 1;
  }
}

@media only screen and (max-width: 1200px) {
}

@media (max-width: 576px) {
  .col-lg-4 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
</style>
