<template>
  <div>
    <CRow>
      <CCol
        :md="tableColSize"
        :lg="tableColSize"
        :xl="tableColSize"
        :xxl="tableColSize"
        xs="12"
        sm="12"
      >
        <CTableWrapper
          :items="data"
          :fields="fields"
          hover
          :striped="true"
          :border="true"
          small
          fixed
          caption="Lista de Campo Deportivo"
          icon="fas fa-coins"
          btn_name="Campo Deportivo"
          @mtdBack="mtdBack"
          @show_modal="mtd_open_modal"
          @action_edit="mtd_show"
          @action_delete="mtd_delete"
          :size="'md'"
          :button_new="true"
          :actions="true"
          :withActions="'2%'"
          :buttonEdit="true"
          :buttonDelete="true"
          :myButtons="myButtons"
          @mtd_table_image="mtd_table_image"
          @mtd_table_sportfieldxsport="mtd_table_sportfieldxsport"
          :buttonBack="true"
        />
      </CCol>
      <CCol
        v-for="table in imageTables"
        :key="table.id"
        md="5"
        lg="5"
        xl="5"
        xxl="5"
        xs="12"
        sm="12"
      >
        <!-- <button
          @click="removeImageTable(table.id)"
          class="btn btn-dark mb-1 d-flex"
        >
          <i class="fas fa-eye"></i>
          <div class="ml-2">Ocultar</div>
        </button>
        <CTableWrapper
          :items="table.data"
          :fields="table.fields"
          hover
          :striped="true"
          :border="true"
          small
          fixed
          :caption="`Lista de Imagenes - ${table.name_sport_field.toUpperCase()}`"
          icon="far fa-file-image"
          btn_name="Imagen"
          @mtdBack="mtdBack"
          @show_modal="mtd_imagen"
          @action_delete="mtd_delete_image"
          :size="'md'"
          :button_new="true"
          :actions="true"
          :withActions="'2%'"
          :buttonEdit="true"
          :buttonDelete="true"
          :myButtons="[]"
          :buttonBack="false"
        /> -->
        <CCard>
          <CCardHeader class="d-flex justify-content-between">
            <span><i class="far fa-file-image"></i>&nbsp;Lista Imagen</span>
            <button
              @click="mtd_imagen"
              type="button"
              class="btn btn-sm text-white"
              style="background: #926cdc"
            >
              <i class="fas fa-plus"></i> Nueva Imagen
            </button>
          </CCardHeader>
          <CCardBody>
            <table
              id="dataTableLists"
              class="table table-sm table-hover table-bordered dt-responsive nowrap"
              style="width: 100%"
            >
              <thead>
                <tr>
                  <th width="60">N°</th>
                  <th>Imagen</th>
                  <th>Acciones</th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="data_images.length === 0" class="text-center">
                  <td colspan="3">No hay data</td>
                </tr>
                <tr
                  v-else
                  v-for="(image, index) in data_images"
                  :key="index"
                  class="text-center"
                >
                  <td>{{ index + 1 }}</td>
                  <td>
                    <img
                      :src="
                        'https://iareservagol.howertpi.com/assets/' + image.path
                      "
                      class="img-thumbnail"
                      style="max-height: 70px"
                      alt="campo deportivo"
                    />
                  </td>
                  <td>
                    <button
                      @click="mtd_update_image(image)"
                      class="btn btn-info btn-sm"
                    >
                      <i class="fas fa-edit"></i>
                    </button>
                    <button
                      @click="mtd_delete_image(image)"
                      class="btn btn-danger btn-sm"
                    >
                      <i class="fas fa-trash"></i>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </CCardBody>
        </CCard>
      </CCol>

      <CCol
        v-for="table in sport_fieldxsportTables"
        :key="table.id"
        md="5"
        lg="5"
        xl="5"
        xxl="5"
        xs="12"
        sm="12"
      >
        <button
          @click="removeSportFieldxSportTable(table.id)"
          class="btn btn-dark mb-1 d-flex"
        >
          <i class="fas fa-eye"></i>
          <div class="ml-2">Ocultar</div>
        </button>
        <CTableWrapper
          :items="table.data"
          :fields="table.fields"
          hover
          :striped="true"
          :border="true"
          small
          fixed
          :caption="`Lista de Deportes - ${table.name_sport_field.toUpperCase()}`"
          icon="far fa-file-image"
          btn_name="Deporte"
          @mtdBack="mtdBack"
          @show_modal="mtd_sportfieldxsport"
          @action_delete="mtd_delete_sportfieldxsport"
          :size="'md'"
          :button_new="true"
          :actions="true"
          :withActions="'2%'"
          :buttonEdit="false"
          :buttonDelete="true"
          :myButtons="[]"
          :buttonBack="false"
        />
      </CCol>
    </CRow>

    <!-- modal -->
    <CModalForm
      :backdrop="true"
      :closeOnBackdrop="true"
      :size="'md'"
      :title="modal.title"
      :button="cp_button"
      :show.sync="modal.modal_form"
      @mtd_action="mtd_action"
      :color="'info'"
      :centered="true"
    >
      <CRow>
        <CCol sm="12">
          <cSelectForm
            label="Sedes"
            placeholder="Seleccione sede"
            :options="data_campu"
            :value.sync="sport_field.campu_id"
            :disabled="this.$store.getters.get__user_id !== 1"
          />
          <cSelectForm
            label="Tipo de campo"
            placeholder="Seleccione tipo de campo"
            :options="data_type_of_field"
            :value.sync="sport_field.type_of_field_id"
            :disabled="!sport_field.campu_id"
          />
          <CInput
            label="Nombre"
            placeholder="Digite nombre"
            v-model="sport_field.name"
          />
        </CCol>
        <CCol v-if="modal.action != 'store'" sm="12">
          <label for="">Estado</label>
          <CSwitchVue
            :key="componentKey"
            @changeSwitch="changeSwitch"
            class="mx-1 pl-2 pt-2"
            :checked="sport_field.state == 1 ? true : false"
            color="success"
            variant="opposite"
            shape="pill"
            v-bind="labelIcon"
          />
        </CCol>
      </CRow>
    </CModalForm>

    <!-- modal image -->
    <CModalForm
      :backdrop="true"
      :closeOnBackdrop="true"
      :size="'md'"
      :title="modal.title"
      :button="cp_button_image"
      :show.sync="modal.modal_form_image"
      @mtd_action="mtd_action_image"
      :color="'info'"
      :centered="true"
    >
      <CRow>
        <CCol sm="12">
          <input
            @change="getFile"
            type="file"
            class="form-control"
            id="file"
            lang="es"
            name="file"
            ref="fileInput"
            accept=".png,.jpg,.jpeg"
          />
          <div
            v-if="image.path && modal.action !== 'store'"
            class="container-img"
          >
            <img
              :src="'https://iareservagol.howertpi.com/assets/' + image.path"
              class="img-thumbnail"
              style="max-width: 100px"
              alt="campo deportivo"
            />
          </div>
        </CCol>
      </CRow>
    </CModalForm>

    <!-- modal Agregar Detalle -->
    <CModalForm
      :backdrop="true"
      :closeOnBackdrop="true"
      :size="'md'"
      :title="modal.title"
      :button="cp_button_sport_fieldxsport"
      :show.sync="modal.modal_form_sport_fieldxsport"
      @mtd_action="mtd_action_sportfieldxsport"
      :color="'info'"
      :centered="true"
    >
      <CRow>
        <CCol sm="12">
          <cSelectForm
            label="Deporte"
            placeholder="Seleccione deporte"
            :options="data_sport"
            :value.sync="sport_fieldxsport.sport_id"
          />
        </CCol>
      </CRow>
    </CModalForm>
    <!-- modal delete -->
    <cModalDelete
      :backdrop="true"
      :closeOnBackdrop="true"
      :size="'md'"
      :title="'Borrar Rol'"
      :show.sync="modal_delete.boo"
      @mtd_commit="mtd_commit"
      :centered="true"
      :item="modal_delete.item"
    />
  </div>
</template>

<script>
const fields = [
  { key: "index", label: "Id", _style: "width:1%" },
  { key: "campu_name", label: "Sede", _style: "width:20%;" },
  { key: "type_of_field_name", label: "Tipo de campo", _style: "width:20%;" },
  { key: "name", label: "Nombre", _style: "width:20%;" },
  { key: "state", label: "Estado", _style: "width:5%;" },
];

import CTableWrapper from "../shared/datatable/Table.vue";
import CModalForm from "../shared/modals/cModalForm.vue";
import cModalDelete from "../shared/modals/cModalDelete.vue";
import { mapActions } from "vuex";
import { bus } from "../../main";
import { CSwitch } from "@coreui/vue-pro";
import CSwitchVue from "../shared/switch/CSwitch.vue";
import cSelectForm from "../../components/shared/inputs/cSelect.vue";

export default {
  components: {
    CTableWrapper,
    CModalForm,
    cModalDelete,
    CSwitchVue,
    cSelectForm,
  },
  data() {
    return {
      prefix: "sports_fields",
      fields,
      tableColSize: 12,
      nextImageTableId: 1,
      nextSportFieldxSportTableId: 1,
      data_images: [],
      imageTables: [],
      sport_fieldxsportTables: [],
      myButtons: [
        {
          class: "btn-sm",
          color: "success",
          tooltip: "Agregar Imagenes",
          action: "mtd_table_image",
          icon: "far fa-file-image",
        },
        {
          class: "btn-sm",
          color: "success",
          tooltip: "Agregar Deporte",
          action: "mtd_table_sportfieldxsport",
          icon: "fas fa-futbol",
        },
      ],
      data: [],
      data_sport: [],
      data_campu: [],
      data_type_of_field: [],
      modal: {
        action: "",
        title: "",
        modal_form: false,
        modal_form_image: false,
        modal_form_sport_fieldxsport: false,
      },
      sport_field: {
        id: "",
        campu_id: "",
        type_of_field_id: "",
        name: "",
        campu_name: "",
        type_of_field_name: "",
        state: 0,
      },
      image: {
        id: "",
        sport_field_id: "",
        path: "",
        state: 0,
      },
      sport_fieldxsport: {
        id: "",
        sport_id: "",
        sport_field_id: "",
      },
      modal_delete: {
        boo: false,
        item: [],
      },
      labelIcon: {
        labelOn: "\u2713",
        labelOff: "\u2715",
      },
      componentKey: 0,
      // dataStore: [],
    };
  },
  computed: {
    cpStatus: function () {
      if (this.sport_field.state == 1) return true;
      return false;
    },
    cp_button: function () {
      if (
        this.sport_field.name != "" &&
        this.sport_field.campu_id &&
        this.sport_field.type_of_field_id
      )
        return false;
      return true;
    },
    cp_button_image: function () {
      if (this.image.path != "") return false;
      return true;
    },
    cp_button_sport_fieldxsport: function () {
      if (this.sport_fieldxsport.sport_id != "") return false;
      return true;
    },
  },
  created() {
    this.mtd_getdata();
    this.indexCampu();

    this.indexSport();
  },
  watch: {
    "sport_field.campu_id": function (newVal) {
      if (newVal) {
        this.indexTypeofField();
      }
    },
  },
  methods: {
    ...mapActions(["get", "post"]),
    getFile(e) {
      let fileInput = this.$refs.fileInput;
      let file = e.target.files[0];

      if (
        !/\.(png)$/i.test(file.name) &&
        !/\.(jpg)$/i.test(file.name) &&
        !/\.(jpeg)$/i.test(file.name)
      ) {
        fileInput.value = "";
      } else {
        this.image.path = file;
      }
    },
    indexSport: function () {
      this.get({
        url: this.$store.getters.get__url + "/sports_fields/sport",
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          response.data.forEach((element) => {
            this.data_sport.push({
              label: element.name,
              value: element.id,
            });
          });
        })
        .catch((errors) => {});
    },
    indexCampu: function () {
      this.get({
        url: this.$store.getters.get__url + "/sports_fields/campu",
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          response.data.data.forEach((element) => {
            this.data_campu.push({
              label: element.name,
              value: element.id,
            });
          });
        })
        .catch((errors) => {});
    },
    indexTypeofField: function () {
      this.get({
        url: this.$store.getters.get__url + "/sports_fields/type_of_field",
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          this.data_type_of_field = [];
          response.data.data.forEach((element) => {
            this.data_type_of_field.push({
              label: element.name,
              value: element.id,
            });
          });
        })
        .catch((errors) => {});
    },
    mtd_getdata: function () {
      let url;
      if (this.$store.getters.get__user_id == 1) {
        url =
          this.$store.getters.get__url +
          "/" +
          this.prefix +
          "/index/" +
          this.$store.getters.get__user_id +
          "/1";
      } else {
        url =
          this.$store.getters.get__url +
          "/" +
          this.prefix +
          "/index/" +
          this.$store.getters.get__user_id +
          "/" +
          this.$store.getters.get_campu.value;
      }
      this.get({
        url: url,
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          this.data = response.data.sports_fields;
          let index = 1;
          this.data.forEach((arr) => {
            arr.index = index;
            index++;
          });
        })
        .catch((errors) => {
          // this.errorsBackend = errors;
          // this.$emit("error", this.event);
        });
    },
    mtdBack: function () {
      this.$emit("mtdBack");
    },
    mtd_open_modal: function (boo, action) {
      this.modal.modal_form = boo;
      this.modal.action = action;
      action == "store"
        ? (this.modal.title = "Nueva Campo Deportivo")
        : (this.modal.title = "Editar Campo Deportivo");
      action == "store"
        ? (this.sport_field = {
            id: "",
            campu_id:
              this.$store.getters.get__user_id == 1
                ? ""
                : this.$store.getters.get_campu.value,
            type_of_field_id: "",
            name: "",
            campu_name: "",
            type_of_field_name: "",
            state: "",
          })
        : (this.sport_field = { ...this.sport_field });
      if (this.modal.action != "store") {
        this.componentKey += 1;
      }
    },
    mtd_action: function () {
      this.post({
        url:
          this.$store.getters.get__url +
          "/" +
          this.prefix +
          "/" +
          this.modal.action,
        token: this.$store.getters.get__token,
        params: this.sport_field,
      })
        .then((response) => {
          if (response.data.state == 1) {
            Swal.fire({
              title: "Campo deportivo ya resgistrado",
              icon: "warning",
            });
          } else {
            let color = "success";
            let message = "";
            if (this.modal.action == "store") {
              message = "REGISTRADO CORRECTAMENTE";
              this.mtd_getdata();
            } else {
              let updatedSport_Field = response.data.data;
              let index = this.data.findIndex(
                (sport_field) => sport_field.id === updatedSport_Field.id
              );
              if (index !== -1) {
                this.$set(this.data, index, updatedSport_Field);
              }
              message = "EDITADO CORRECTAMENTE";
              this.mtd_getdata();
            }
            this.modal = {
              action: "",
              title: "",
              modal_form: false,
              modal_form_image: false,
              modal_form_sport_fieldxsport: false,
            };
            this.sport_field = {
              id: "",
              campu_id: "",
              type_of_field_id: "",
              name: "",
              campu_name: "",
              type_of_field_name: "",
              state: "",
            };
            bus.$emit("alert", {
              color: color,
              message: message,
            });
          }
        })
        .catch((errors) => {
          bus.$emit("alert", {
            color: "danger",
            message: errors,
          });
        });
    },
    mtd_show: function (id) {
      this.get({
        url:
          this.$store.getters.get__url + "/" + this.prefix + "/" + id + "/show",
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          this.sport_field = response;
          this.mtd_open_modal(true, "update");
        })
        .catch((errors) => {});
    },
    mtd_delete: function (item) {
      this.modal_delete.boo = true;
      this.modal_delete.item = item;
    },
    close_delete: function () {
      this.modal_delete.boo = false;
    },
    mtd_commit: function () {
      this.post({
        url: this.$store.getters.get__url + "/" + this.prefix + "/destroy",
        token: this.$store.getters.get__token,
        params: {
          id: this.modal_delete.item.id,
        },
      })
        .then((response) => {
          let temp = [];
          this.data.forEach((element) => {
            if (element.id != response.data.id) {
              temp.push(element);
            }
          });
          this.data = temp;
          this.modal_delete = {
            boo: false,
            item: [],
          };

          bus.$emit("alert", {
            color: "success",
            message: "ELIMINADO CORRECTAMENTE",
          });
        })
        .catch((errors) => {});
    },
    changeSwitch: function (payload) {
      this.sport_field.state = payload == true ? 1 : 0;
    },
    mtd_table_image: function (id) {
      if (this.sport_fieldxsportTables.length != 0) {
        this.removeSportFieldxSportTable(this.sport_fieldxsportTables[0].id);
      }
      if (this.imageTables.length == 1) {
        this.removeImageTable(this.imageTables[0].id);
      }

      this.tableColSize = 7;
      this.image.sport_field_id = id.id;
      this.image.path = "";

      // Add a new image table
      if (this.nextImageTableId == 1) {
        const newImageTable = {
          id: this.nextImageTableId,
          data: [],
          fields: [
            { key: "index", label: "Id", _style: "width:1%" },
            { key: "path", label: "Nombre", _style: "width:20%;" },
          ],
          name_sport_field: id.name,
        };
        this.imageTables.push(newImageTable);
        this.mtd_getdata_image(newImageTable.id, id.id);
        this.nextImageTableId += 1;
      }
    },
    removeImageTable(tableId) {
      this.nextImageTableId = 1;
      this.imageTables = this.imageTables.filter(
        (table) => table.id !== tableId
      );

      // Si no quedan más tablas de códigos de barras, restablece el tamaño de la columna principal
      if (this.imageTables.length === 0) {
        this.tableColSize = 12;
      }
    },
    mtd_imagen: function () {
      this.tableColSize = 7;
      this.modal.modal_form_image = true;
      this.modal.action = "store";
      this.modal.title = "Agregar Imagenes";
      this.image.path = "";
    },
    mtd_update_image: function (item) {
      this.tableColSize = 7;
      this.modal.modal_form_image = true;
      this.modal.action = "update";
      this.modal.title = "Editar Imagen";
      this.image.id = item.id;
      this.image.path = item.path;
    },
    mtd_action_image: function () {
      let color = "success";
      let message = "";
      let formData = new FormData();
      formData.append("id", this.image.id);
      formData.append("sport_field_id", this.image.sport_field_id);
      formData.append("path", this.image.path);
      this.post({
        url: this.$store.getters.get__url + "/sports_fields/"+this.modal.action+"/images",
        token: this.$store.getters.get__token,
        params: formData,
      })
        .then((response) => {
          if(this.modal.action == "store"){
            message = "REGISTRADO CORRECTAMENTE";
            this.mtd_getdata_image(
              this.imageTables[0].id,
              this.image.sport_field_id
            );
          }else{
            message = "ACTUALIZADO CORRECTAMENTE";
            this.mtd_getdata_image(
              this.imageTables[0].id,
              this.image.sport_field_id
            );
          }
          this.modal = {
            action: "",
            title: "",
            modal_form: false,
            modal_form_image: false,
            modal_form_sport_fieldxsport: false,
          };
          bus.$emit("alert", {
            color: color,
            message: message,
          });
        })
        .catch((errors) => {
          bus.$emit("alert", {
            color: "danger",
            message: errors,
          });
        });
    },
    mtd_getdata_image: function (tableId, sport_fieldId) {
      this.get({
        url:
          this.$store.getters.get__url +
          "/" +
          this.prefix +
          "/byImage/" +
          sport_fieldId,
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          this.data_images = response.data.data;
          const tableIndex = this.imageTables.findIndex(
            (table) => table.id === tableId
          );
          if (tableIndex !== -1) {
            this.$set(this.imageTables[tableIndex], "data", response.data.data);
            // Agregar un índice a cada elemento en la data
            let index = 1;
            this.imageTables[tableIndex].data.forEach((item) => {
              item.index = index;
              index++;
            });
          }
        })
        .catch((errors) => {
          // this.errorsBackend = errors;
          // this.$emit("error", this.event);
        });
    },
    mtd_delete_image(item) {
      Swal.fire({
        title: "¿Estás seguro?",
        text: "¡No podrás revertir esto!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sí, eliminarlo",
      }).then((result) => {
        if (result.isConfirmed) {
          this.post({
            url: this.$store.getters.get__url + "/images/destroy",
            token: this.$store.getters.get__token,
            params: {
              id: item.id,
            },
          })
            .then((response) => {
              if (response.data) {
                // Eliminar la imagen de la tabla localmente
                const tableIndex = this.imageTables.findIndex(
                  (table) => table.id == this.nextImageTableId - 1
                );

                if (tableIndex !== -1) {
                  const imageIndex = this.imageTables[
                    tableIndex
                  ].data.findIndex((imagen) => imagen.id === item.id);
                  if (imageIndex !== -1) {
                    this.imageTables[tableIndex].data.splice(imageIndex, 1);
                  }
                }
                Swal.fire(
                  "¡Eliminado!",
                  "La imagen ha sido eliminada.",
                  "success"
                );
              }
            })
            .catch((error) => {
              Swal.fire(
                "Error",
                "Ocurrió un error al eliminar la imagen.",
                "error"
              );
            });
        }
      });
    },

    mtd_table_sportfieldxsport: function (id) {
      if (this.imageTables.length != 0) {
        this.removeImageTable(this.imageTables[0].id);
      }
      if (this.sport_fieldxsportTables.length == 1) {
        this.removeSportFieldxSportTable(this.sport_fieldxsportTables[0].id);
      }

      this.tableColSize = 7;
      this.sport_fieldxsport.sport_field_id = id.id;
      this.sport_fieldxsport.sport_id = "";

      if (this.nextSportFieldxSportTableId == 1) {
        const newSportFieldxSportTable = {
          id: this.nextSportFieldxSportTableId,
          data: [],
          fields: [
            { key: "index", label: "Id", _style: "width:1%" },
            { key: "name", label: "Nombre", _style: "width:20%;" },
          ],
          name_sport_field: id.name,
        };
        this.sport_fieldxsportTables.push(newSportFieldxSportTable);
        this.mtd_getdata_sport(newSportFieldxSportTable.id, id.id);
        this.nextSportFieldxSportTableId += 1;
      }
    },
    removeSportFieldxSportTable(tableId) {
      this.nextSportFieldxSportTableId = 1;
      this.sport_fieldxsportTables = this.sport_fieldxsportTables.filter(
        (table) => table.id !== tableId
      );

      if (this.sport_fieldxsportTables.length === 0) {
        this.tableColSize = 12;
      }
    },
    mtd_sportfieldxsport: function () {
      this.tableColSize = 7;
      this.modal.modal_form_sport_fieldxsport = true;
      this.modal.title = "Agregar Deporte";
      this.sport_fieldxsport.sport_id = "";
    },
    mtd_action_sportfieldxsport: function () {
      let color = "success";
      let message = "";
      this.post({
        url:
          this.$store.getters.get__url +
          "/sports_fields/store/sportfieldxsport",
        token: this.$store.getters.get__token,
        params: this.sport_fieldxsport,
      })
        .then((response) => {
          if (response.data.state == 1) {
            Swal.fire({
              title: "Deporte ya resgistrado en el campo deportivo",
              icon: "warning",
            });
          } else {
            message = "REGISTRADO CORRECTAMENTE";
            this.mtd_getdata_sport(
              this.sport_fieldxsportTables[0].id,
              this.sport_fieldxsport.sport_field_id
            );
            this.modal = {
              action: "",
              title: "",
              modal_form: false,
              modal_form_image: false,
              modal_form_sport_fieldxsport: false,
            };
            this.sport_fieldxsport = {
              id: "",
              sport_id: "",
              sport_field_id: "",
            };
            bus.$emit("alert", {
              color: color,
              message: message,
            });
          }
        })
        .catch((errors) => {
          bus.$emit("alert", {
            color: "danger",
            message: errors,
          });
        });
    },

    mtd_getdata_sport: function (tableId, sport_fieldId) {
      this.get({
        url:
          this.$store.getters.get__url +
          "/" +
          this.prefix +
          "/bySport_FieldxSport/" +
          sport_fieldId,
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          const tableIndex = this.sport_fieldxsportTables.findIndex(
            (table) => table.id === tableId
          );
          if (tableIndex !== -1) {
            this.$set(
              this.sport_fieldxsportTables[tableIndex],
              "data",
              response.data.data
            );
            let index = 1;
            this.sport_fieldxsportTables[tableIndex].data.forEach((item) => {
              item.index = index;
              index++;
            });
          }
        })
        .catch((errors) => {
          // this.errorsBackend = errors;
          // this.$emit("error", this.event);
        });
    },
    mtd_delete_sportfieldxsport(item) {
      // Muestra una confirmación antes de eliminar
      Swal.fire({
        title: "¿Estás seguro?",
        text: "¡No podrás revertir esto!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sí, eliminarlo",
      }).then((result) => {
        if (result.isConfirmed) {
          this.post({
            url: this.$store.getters.get__url + "/sports_fieldsxsports/destroy",
            token: this.$store.getters.get__token,
            params: {
              id: item.id,
            },
          })
            .then((response) => {
              if (response.data) {
                // Eliminar el deporte de la tabla localmente
                const tableIndex = this.sport_fieldxsportTables.findIndex(
                  (table) => table.id == this.nextSportFieldxSportTableId - 1
                );

                if (tableIndex !== -1) {
                  const sportIndex = this.sport_fieldxsportTables[
                    tableIndex
                  ].data.findIndex((sport) => sport.id === item.id);
                  if (sportIndex !== -1) {
                    this.sport_fieldxsportTables[tableIndex].data.splice(
                      sportIndex,
                      1
                    );
                  }
                }
                Swal.fire(
                  "¡Eliminado!",
                  "El deporte ha sido eliminado.",
                  "success"
                );
              }
            })
            .catch((error) => {
              Swal.fire(
                "Error",
                "Ocurrió un error al eliminar el deporte.",
                "error"
              );
            });
        }
      });
    },
  },
  destroyed() {
    // console.log(this.dataStore);
    // this.$store.commit("mt_set_datacampus", this.dataStore);
  },
};
</script>

<style lang="scss" scoped>
.btn-search {
  color: #fff;
  background-color: #2819ae;
  border-color: #2517a3;
}
.container-img{
  margin: 10px 0 0 0;
  display: flex;
  justify-content: center;
}
</style>
