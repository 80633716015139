<template>
    <div>
      <CRow>
        <CCol md="12" lg="12" xl="12" xxl="12" xs="12" sm="12">
          <CTableWrapper
            :items="data"
            :fields="fields"
            hover
            :striped="true"
            :border="true"
            small
            fixed
            caption="Lista de reserva"
            icon="fas fa-coins"
            btn_name="Reserva"
            @mtdBack="mtdBack"
            @show_modal="mtd_open_modal"
            @action_edit="mtd_show"
            @action_delete="mtd_delete"
            :size="'md'"
            :button_new="false"
            :actions="true"
            :withActions="'2%'"
            :buttonEdit="true"
            :buttonDelete="true"
          />
        </CCol>
      </CRow>
  
      <!-- modal -->
      <CModalForm
        :backdrop="true"
        :closeOnBackdrop="true"
        :size="'md'"
        :title="modal.title"
        :button="cp_button"
        :show.sync="modal.modal_form"
        @mtd_action="mtd_action"
        :color="'info'"
        :centered="true"
      >
        <CRow>
          <CCol sm="12">
            <CInput
              label="Fecha de Reserva"
              v-model="booking.reservation_date"
              readonly
            />
          </CCol>
          <CCol sm="12">
            <cSelectForm
              label="Campo Deportivo"
              placeholder="Seleccione campo deportivo"
              :options="data_sport_field"
              :value.sync="booking.sport_fieldxsport.sport_field.id"
              @change="indexSport"
            />
          </CCol>
          <CCol sm="12">
            <cSelectForm
              label="Deporte"
              placeholder="Seleccione deporte"
              :options="data_sport"
              :value.sync="booking.sport_fieldxsport.sport.id"
            />
          </CCol>
          <CCol sm="12">
            <CInput
              label="Cliente"
              v-model="booking.name"
            />
          </CCol>

          <CCol sm="12">
            <CInput
              label="Telefono"
              v-model="booking.phone"
            />
          </CCol>
          
          <CCol sm="12">
            <CInput
              label="Hora de inicio"
              v-model="booking.start_time"
            />
          </CCol>

          <CCol sm="12">
            <CInput
              label="Hora Final"
              v-model="booking.end_time"
            />
          </CCol>

          <CCol sm="12">
            <CInput
              label="Tiempo"
              v-model="booking.hours"
            />
          </CCol>
          <CCol sm="12">
            <CInput
              label="Total a pagar"
              v-model="booking.total_pay"
            />
          </CCol>
          <CCol sm="12">
            <label for="state">Estado</label>
            <div class="d-flex align-items-center justify-content-between">
              <div class="">
                <input type="radio" id="state0" value="0" v-model="booking.state">
                <label class="mx-1" for="state0">Cancelada</label>
              </div>
              <div>
                <input type="radio" id="state1" value="1" v-model="booking.state">
                <label class="mx-1" for="state1">Reservada</label>
              </div>
              <div>
                <input type="radio" id="state2" value="2" v-model="booking.state">
                <label class="mx-1" for="state2">Pagado</label>
              </div>
              <div>
                <input type="radio" id="state3" value="3" v-model="booking.state">
                <label class="mx-1" for="state3">Terminado</label>
              </div>
            </div>
          </CCol>
          <CCol sm="12" class="mt-2 d-flex align-items-center text-center">
            <div>En curso</div>
            <input
              type="checkbox"
              class="mx-2"
            />
          </CCol>
          <CCol sm="12" class="mt-2">
            <CInput 
              label="En curso"
              type="checkbox"
            />
          </CCol>
          

          <!-- <CCol v-if="modal.action != 'store'" sm="12">
            <label for="">Estado</label>
            <CSwitchVue
              :key="componentKey"
              @changeSwitch="changeSwitch"
              class="mx-1 pl-2 pt-2"
              :checked="booking.state == 1 ? true : false"
              color="success"
              variant="opposite"
              shape="pill"
              v-bind="labelIcon"
            />
          </CCol> -->
        </CRow>
      </CModalForm>
  
      <!-- modal delete -->
      <cModalDelete
        :backdrop="true"
        :closeOnBackdrop="true"
        :size="'md'"
        :title="'Borrar Reserva'"
        :show.sync="modal_delete.boo"
        @mtd_commit="mtd_commit"
        :centered="true"
        :item="modal_delete.item"
      />
    </div>
  </template>
  
  <script>
  const fields = [
    { key: "id", label: "Id", _style: "width:1%" },
    { key: "employee_name", label: "Recepcionista", _style: "width:20%;" },
    { key: "sportsman_name", label: "Cliente", _style: "width:20%;" },
    { key: "reservation_date", label: "Fecha de reservación", _style: "width:20%;" },
    // { key: "name", label: "Nombre del Contacto", _style: "width:20%;" },
    { key: "phone", label: "Telefono del Contacto", _style: "width:20%;" },
    { key: "total_pay", label: "Total a pagar", _style: "width:20%;" },
    { key: 'nombreState', label: "Estado", _style: "width:5%;"},
  ];
  
  import CTableWrapper from "../../shared/datatable/Table.vue";
  import CModalForm from "../../shared/modals/cModalForm.vue";
  import cModalDelete from "../../shared/modals/cModalDelete.vue";
  import { mapActions } from "vuex";
  import { bus } from "../../../main";
  import { CCol, CSwitch } from "@coreui/vue-pro";
  import CSwitchVue from "../../shared/switch/CSwitch.vue";
  import cSelectForm from "../../shared/inputs/cSelect.vue";

  
  export default {
    components: { CTableWrapper, CModalForm, cModalDelete, CSwitchVue, cSelectForm },
    data() {
      return {
        prefix: "bookings",
        fields,
        data_sport_field: [],
        data_sport: [],

        data: [],
        modal: {
          action: "",
          title: "",
          modal_form: false,
        },

        booking:{
          id:"",
          employee_id:"",
          sport_field_id: "",
          sport_id:"",
          sport_fieldxsport_id:"",
          reservation_date:"",
          reservation_day:"",
          sport_man_id:"",
          sport_man_name:"",
          sport_man_phone:"",
          sport_man_dni:"",
          start_time:"",
          end_time:"",
          cant_hours:"",
          subtotal_pay:"",
          total_pay:"",
          state:0,
          sport_fieldxsport:{
            sport_field: {
              id:""
            },
            sport:{
              id:""
            }
          },
        },

        modal_delete: {
          boo: false,
          item: [],
        },
        labelIcon: {
          labelOn: "\u2713",
          labelOff: "\u2715",
        },
        componentKey: 0,
        // dataStore: [],
      };
    },
    computed: {
      cpStatus: function () {
        if (this.booking.state == 1) return true;
        return false;
      },
      cp_button: function () {
        if (this.booking.reservation_date != "") return false;
        return true;
      },
    },
    created() {
      this.mtd_getdata();
      this.indexSport_Field();

    },
    watch: {
      "booking.sport_fieldxsport.sport_field.id": function(newId) 
      {
        // if (newId) {
        //   this.indexSport();
        // }
        if (newId && this.booking.sport_fieldxsport  && this.booking.sport_fieldxsport.sport_field) {
          this.indexSport();
        }
      },
    },

    methods: {
      ...mapActions(["get", "post"]),
      mtd_getdata: function () {
        this.get({
          url: this.$store.getters.get__url + "/" + this.prefix,
          token: this.$store.getters.get__token,
        })
          .then((response) => {
            this.data = response.data.data;
            this.data.forEach(item => {
            switch (item.state) {
                case 0:
                    item['nombreState'] = "Cancelado";
                    break;
                case 1:
                    item['nombreState'] = "Reservado";
                    break;
                case 2:
                    item['nombreState'] = "Pagado";
                    break;
                case 3:
                    item['nombreState'] = "Terminado";
                    break;
                default:
                    item['nombreState'] = "Reservado";
            }
        });
          })
          .catch((errors) => {
            // this.errorsBackend = errors;
            // this.$emit("error", this.event);
          });
      },
      mtdBack: function () {
        //this.$emit("mtdBack");
        this.$emit('setView','home');
      },
      mtd_open_modal: function (boo, action) {
        this.modal.modal_form = boo;
        this.modal.action = action;
        action == "store"
          ? (this.modal.title = "Nueva Reserva")
          : (this.modal.title = "Editar Reserva");
        action == "store"
          ? (this.booking = {
              id:"",
              employee_id:"",
              sport_field_id: "",
              sport_id:"",
              sport_fieldxsport_id:"",
              reservation_date:"",
              reservation_day:"",
              sport_man_id:"",
              sport_man_name:"",
              sport_man_phone:"",
              sport_man_dni:"",
              start_time:"",
              end_time:"",
              cant_hours:"",
              subtotal_pay:"",
              total_pay:"",
              state:0,
              sport_fieldxsport:{
                sport_field: {
                  id:""
                },
                sport:{
                  id:""
                }
              },
            })
          : (this.booking = {...this.booking});
        if (this.modal.action != "store") {
          this.componentKey += 1;
        }
      },
      mtd_action: function () {
        this.post({
          url:
            this.$store.getters.get__url +
            "/" +
            this.prefix +
            "/" +
            this.modal.action,
          token: this.$store.getters.get__token,
          params: this.booking,
        })
          .then((response) => {
            let color = "success";
            let message = "";
            if (this.modal.action == "store") {
              message = "REGISTRADO CORRECTAMENTE";
              this.mtd_getdata();
            } else {
              let updatedBooking = response.data.data;
              let index = this.data.findIndex(booking => booking.id === updatedBooking.id);
              if (index !== -1) {
                this.$set(this.data, index, updatedBooking);
              }
              message = "EDITADO CORRECTAMENTE";
              this.mtd_getdata();
            }
            this.modal = {
              action: "",
              title: "",
              modal_form: false,
              name: "",
            };
            this.booking = {
              id:"",
              employee_id:"",
              sport_field_id: "",
              sport_id:"",
              sport_fieldxsport_id:"",
              reservation_date:"",
              reservation_day:"",
              sport_man_id:"",
              sport_man_name:"",
              sport_man_phone:"",
              sport_man_dni:"",
              start_time:"",
              end_time:"",
              cant_hours:"",
              subtotal_pay:"",
              total_pay:"",
              state:0,
              sport_fieldxsport:{
                sport_field: {
                  id:""
                },
                sport:{
                  id:""
                }
              },
            }; 
  
            bus.$emit("alert", {
              color: color,
              message: message,
            });
          })
          .catch((errors) => {
            bus.$emit("alert", {
              color: "danger",
              message: errors,
            });
          });
      },
      mtd_show: function (id) {
        this.get({
          url:
            this.$store.getters.get__url + "/" + this.prefix + "/" + id + "/show",
          token: this.$store.getters.get__token,
        })
          .then((response) => {
            this.booking = response;
            this.mtd_open_modal(true, "update");
          })
          .catch((errors) => {});
      },
      mtd_delete: function (item) {
        this.modal_delete.boo = true;
        this.modal_delete.item = item;
      },
      close_delete: function () {
        this.modal_delete.boo = false;
      },
      
      mtd_commit: function () {
        this.post({
          url: this.$store.getters.get__url + "/" + this.prefix + "/destroy",
          token: this.$store.getters.get__token,
          params: {
            id: this.modal_delete.item.id,
          },
        })
          .then((response) => {
            let temp = [];
            this.data.forEach((element) => {
              if (element.id != response.data.id) {
                temp.push(element);
              }
            });
            this.data = temp;
            this.modal_delete = {
              boo: false,
              item: [],
            };
  
            bus.$emit("alert", {
              color: "success",
              message: "ELIMINADO CORRECTAMENTE",
            });
          })
          .catch((errors) => {});
      },
      changeSwitch: function (payload) {
        this.booking.state = payload == true ? 1 : 0;
      },
      indexSport_Field: function(){
        this.get({
            url: this.$store.getters.get__url+"/bookings/sport_field/"+this.findId_Campu(),
            token: this.$store.getters.get__token,
          })
          .then((response) => {
              response.data.sports_fields.forEach((element) => {
                this.data_sport_field.push({
                  label: element.name,
                  value: element.id,
                });
              });
            })
        .catch((errors) => {});
      },
      findId_Campu: function () {
        const campusList = this.$store.getters.get_campus;
        const targetLabel = this.$store.getters.get_campu.label;
        let campusId = null;

        for (const campus of campusList) {
          if (campus.name === targetLabel) {
            campusId = campus.id;
            break;
          }
        }
        return campusId;
      },
      
      indexSport: function(){
        this.get({
            url: this.$store.getters.get__url+"/bookings/sport/"+this.booking.sport_fieldxsport.sport_field.id,
            token: this.$store.getters.get__token,
        })
        .then((response) => {
          this.data_sport=[];
            response.data.sports_fieldsxsport.forEach((element) => {
              if (!this.data_sport.find(item => item.value === element.sport_id)) {
                this.data_sport.push({
                  label: element.sport_name,
                  value: element.sport_id,
                });
              }
            });
          })
        .catch((errors) => {});
      },  
      
    },
    destroyed() {
      // console.log(this.dataStore);
      // this.$store.commit("mt_set_datacampus", this.dataStore);
    },
    
    
  };
  </script>
  
  <style lang="scss" scoped>
  .btn-search {
    color: #fff;
    background-color: #2819ae;
    border-color: #2517a3;
  }
  </style>