<template>
  <div class="container mx-auto">
    <CRow>
      <CCol xs="12" sm="12" md="12" lg="4" xl="4">
        <CCard class="pb-3">
          <CCardHeader class="d-flex justify-content-between">
            <span><i class="fas fa-store"></i>&nbsp;Venta</span>
            <button
              @click="returnHome"
              type="button"
              class="btn btn-sm text-white"
              style="background: #926cdc"
            >
              <i class="fas fa-hand-point-left"></i> Retroceder
            </button>
          </CCardHeader>
          <CCardBody
            v-for="(item, index) in popularProducts"
            :key="index"
            class="container-card-body mt-3 mx-3"
            @click="addPopularProduct(item)"
            :class="item.stock == 0 ? '' : 'bg-info'"
          >
            <CRow>
              <CCol
                xs="12"
                sm="12"
                md="12"
                lg="12"
                class="d-flex justify-content-between font-bold"
              >
                <div>{{ item.label }}</div>
                <div>Precio: {{ item.price }}</div>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      </CCol>
      <CCol xs="12" sm="12" md="12" lg="8" xl="8">
        <CCard>
          <CCardHeader class="d-flex justify-content-between">
            <span><i class="fas fa-coins"></i>&nbsp;Producto</span>
            <div class="d-flex">
              <div class="mr-5 font-bold">
                <h4>
                  <b>Total S/{{ calculateTotal }}</b>
                </h4>
              </div>
              <button
                type="button"
                class="btn btn-sm text-white"
                style="background: #39f"
                @click="mtdOpenModalSale"
              >
                <i class="fas fa-dollar-sign"></i>
                <b>Terminar Venta</b>
              </button>
            </div>
          </CCardHeader>
          <CCardBody>
            <CRow>
              <CCol xs="12" sm="12" md="6" lg="6">
                <CInput
                  label="Código de Producto"
                  placeholder="Digite de barras mediante la lectora"
                  v-model="searchBarcode"
                  @keypress.enter="filterProductoBarcode"
                />
              </CCol>
              <CCol xs="12" sm="12" md="6" lg="6">
                <CInput
                  label="Buscar Producto"
                  type="search"
                  v-model="searchQuery"
                  @keypress.enter="filterProducts"
                  placeholder="Digite nombre de producto.."
                />
              </CCol>
            </CRow>
          </CCardBody>
          <CCardHeader class="d-flex justify-content-start">
            <span><i class="fa fa-store"></i>&nbsp; Detalle de venta </span>
          </CCardHeader>
          <CCardBody>
            <table
              id="dataTableLists"
              class="table table-sm table-hover table-bordered dt-responsive nowrap"
              style="width: 100%"
            >
              <thead>
                <tr>
                  <th width="60">N°</th>
                  <th>Producto</th>
                  <th>Unidad</th>
                  <th>Stock</th>
                  <th>Precio</th>
                  <th>Cantidad</th>
                  <th>Subtotal</th>
                  <th>Acciones</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(product, index) in productList"
                  :key="index"
                  @click="openProductModal(product)"
                  style="cursor: pointer"
                >
                  <td>{{ index + 1 }}</td>
                  <td>{{ product.product.name }}</td>
                  <td>{{ product.unit.name }}</td>
                  <td>{{ product.stock }}</td>
                  <td>{{ product.price }}</td>
                  <td>{{ product.quantity }}</td>
                  <td>{{ (product.price * product.quantity).toFixed(2) }}</td>
                  <td>
                    <button
                      @click.stop="removeProduct(index)"
                      class="btn btn-danger btn-sm"
                    >
                      <i class="fas fa-trash"></i> Eliminar
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
    <div class="container mx-auto p-4">
      <!-- Existing content -->
      <cModalSale
        :title="modalSale.title"
        :productList="productList"
        :sale="sale"
        :boo_modal="modalSale.modal_form"
        @closeModalSale="closeModalSale"
        @save="handleSave"
      />

      <cModalUpdateSale
        :title="modalUpdateSale.title"
        :boo_modal="modalUpdateSale.modal_form"
        :product="modalUpdateSale.product"
        @closeModalUpdateSale="closeModalUpdateSale"
        @updateProduct="handleUpdateProduct"
      />

      <cModalProductList
        :products="filteredProducts"
        :boo_modal="modalProductList.modal_form"
        @close="closeProductListModal"
        @selectProduct="selectProduct"
      />
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import moment from "moment";
import cSelectForm from "../../../shared/inputs/cSelect.vue";
import CTableWrapper from "../../../../components/shared/datatable/Table.vue";
import { bus } from "../../../../main";
import cModalSale from "../modals/cModalSale.vue";
import cModalUpdateSale from "../modals/cModalUpdateSale.vue";
import cModalProductList from "../modals/cModalProductList.vue";
export default {
  name: "sales",
  components: {
    cSelectForm,
    CTableWrapper,
    cModalSale,
    cModalUpdateSale,
    cModalProductList,
  },
  data() {
    return {
      prefix: "sales",
      data_product: [],
      estado: true,
      searchQuery: "", // Para almacenar lo que escribe el usuario
      searchBarcode: "", // Para almacenar lo que escribe el usuario
      filteredProducts: [], // Para almacenar los productos filtrados
      filteredProductsBarcode: [], // Para almacenar los productos filtrados
      productList: [],
      popularProducts: [],
      sale: {
        id: "",
        company_id: this.$store.getters.get_company.id,
        campu_id: this.$store.getters.get_campu.value,
        user_id: this.$store.getters.get__user_id,
        document_type_id: "",
        sportsman_id: "",
        date: "",
        method: "",
        type: "",
        total: "",
        amount: "",
        pending: "",
        ruc: "",
        existingSportman: true,
        state: 0,
        entity_document: "",
        entity_name: "",
        entity_address: "",
        box_id: "",
        bead_id: "",
      },
      newProduct: {
        product: {
          id: "",
          name: "",
        },
        price: "",
        quantity: 1,
        subtotal: 0,
        stock: 0,
      },
      modalSale: {
        title: "Terminar Venta",
        modal_form: false,
      },
      modalUpdateSale: {
        title: "Editar",
        modal_form: false,
        product: {
          product: {
            id: "",
            name: "",
          },
          price: "",
          quantity: "",
          subtotal: "",
        },
      },
      modalProductList: {
        modal_form: false,
      },
    };
  },
  computed: {
    calculateSubtotal() {
      return (this.newProduct.subtotal =
        this.newProduct.price * this.newProduct.quantity);
    },
    calculateTotal() {
      return this.productList.reduce((total, product) => {
        return total + product.price * product.quantity;
      }, 0);
    },
    cpButton() {},
  },
  watch: {
    productList: {
      handler() {
        this.sale.total = this.calculateTotal.toFixed(2);
      },
      deep: true,
    },
  },
  created() {
    this.showProduct();
    this.showPopularProducts();
    this.showBead();
  },
  methods: {
    ...mapActions(["get", "post"]),
    returnHome() {
      this.$emit("returnHome");
    },
    showPopularProducts() {
      this.get({
        url:
          this.$store.getters.get__url +
          "/" +
          this.prefix +
          "/byPopularProducts/" +
          this.$store.getters.get_campu.value,
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          const stockMap = new Map();

          // Mapeo de stock por product_unit_id para acceso rápido
          response.data.stock.forEach(({ product_unit, stock }) => {
            stockMap.set(product_unit.id, {
              productName: product_unit.product.name,
              productId: product_unit.product.id,
              unitName: product_unit.unit.name,
              unitId: product_unit.unit.id,
              price: product_unit.product.price[0].sale_price,
              stock: stock,
            });
          });

          // Construcción de popularProducts combinando la información del stock y popular
          response.data.popular.forEach(
            ({ product_unit_id, total_quantity }, index) => {
              if (index < 7) {
                const stockInfo = stockMap.get(product_unit_id);
                if (stockInfo) {
                  this.popularProducts.push({
                    label: stockInfo.productName,
                    value: stockInfo.productId,
                    label_unit: stockInfo.unitName,
                    value_unit: stockInfo.unitId,
                    price: stockInfo.price,
                    quantity: total_quantity,
                    stock: stockInfo.stock,
                  });
                }
              }
            }
          );
          //opcional: si aún quieres limitar los productos populares después del procesamiento
          this.popularProducts = this.popularProducts.slice(0, 7);
        })
        .catch((errors) => {});
    },
    showProduct() {
      this.get({
        url:
          this.$store.getters.get__url +
          "/" +
          this.prefix +
          "/byProduct/" +
          this.$store.getters.get_campu.value,
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          response.data.data.forEach((arr) => {
            const { stock, product_unit } = arr;
            this.data_product.push({
              label: product_unit.product.name,
              value: product_unit.product.id,
              price: product_unit.product.price[0].sale_price,
              value_unit: product_unit.unit.id,
              label_unit: product_unit.unit.name,
              barcodes: product_unit.product.barcode,
              stock: stock,
            });
          });
        })
        .catch((errors) => {});
    },
    filterProducts() {
      if (this.searchQuery) {
        this.filteredProducts = this.data_product.filter((product) =>
          product.label.toLowerCase().includes(this.searchQuery.toLowerCase())
        );

        // Show the modal with the filtered products
        if (this.filteredProducts.length > 0) {
          this.modalProductList.modal_form = true;
        }
      }
    },
    selectProduct(product) {
      const existingProduct = this.productList.find(
        (p) =>
          p.product.id === product.value && p.unit.id === product.value_unit
      );

      if (existingProduct) {
        // If the product exists, increment its quantity
        if (existingProduct.quantity < product.stock) {
          existingProduct.quantity += 1;
        } else {
          Swal.fire({
            title: "La cantidad excede del stock disponible",
            icon: "warning",
          });
        }
      } else {
        // If the product doesn't exist, add it to the list
        const newProduct = {
          product: {
            id: product.value,
            name: product.label,
          },
          unit: {
            id: product.value_unit,
            name: product.label_unit,
          },
          price: product.price,
          quantity: 1,
          subtotal: product.price,
          stock: product.stock,
        };
        this.productList.push(newProduct);
      }

      // Close the modal after selection
      this.closeProductListModal();
    },
    closeProductListModal() {
      this.modalProductList.modal_form = false;
    },
    removeProduct(index) {
      // this.productList.splice(index, 1);
      Swal.fire({
        title: "¿Desea eliminar el producto?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sí, eliminar",
        cancelButtonText: "Cancelar",
      }).then((result) => {
        if (result.isConfirmed) {
          // Si el usuario confirma, se elimina el producto
          this.productList.splice(index, 1);
          Swal.fire("Eliminado!", "El producto ha sido eliminado.", "success");
        }
      });
    },
    addPopularProduct(item) {
      if (item.stock != 0) {
        const existingProduct = this.productList.find(
          (product) => product.product.name === item.label
        );

        if (existingProduct) {
          // If the product exists, increment the quantity
          if (existingProduct.quantity < item.stock) {
            existingProduct.quantity += 1;
          } else {
            Swal.fire({
              title: "La cantidad excede del stock disponible",
              icon: "warning",
            });
          }
        } else {
          // If the product doesn't exist, add it to the list
          const productToAdd = {
            product: {
              id: item.value, // Use item.value for the ID
              name: item.label,
            },
            unit: {
              id: item.value_unit,
              name: item.label_unit,
            },
            price: item.price,
            quantity: 1,
            subtotal: item.price,
            stock: item.stock,
          };
          this.productList.push(productToAdd);
        }
      } else {
        Swal.fire({
          title: "No hay stock disponible",
          icon: "warning",
        });
      }
    },

    // Existing methods
    handleSave() {
      // Restablecer la lista de productos y la venta después de guardar
      this.productList = [];
      this.sale = {
        id: "",
        company_id: this.$store.getters.get_company.id,
        campu_id: this.$store.getters.get_campu.value,
        user_id: this.$store.getters.get__user_id,
        document_type_id: "",
        sportsman_id: "",
        date: "",
        method: "",
        type: "",
        total: "",
        amount: "",
        pending: "",
        ruc: "",
        state: 0,
        sport_man_dni: "",
        sport_man_name: "",
        existingSportman: true,
        entity_document: "",
        entity_name: "",
      };
      this.modalSale.modal_form = false;

      //limpio datos
      this.data_product = [];
      this.popularProducts = [];
      //recargo denuevo
      this.showProduct();
      this.showPopularProducts();
      this.showBead();
    },
    mtdOpenModalSale() {
      this.modalSale = {
        title: "TERMINAR VENTA",
        modal_form: true,
      };
    },
    closeModalSale() {
      this.modalSale = {
        modal_form: false,
      };
      this.sale.entity_document = "";
      this.sale.entity_name = "";
    },
    openProductModal(product) {
      this.modalUpdateSale = {
        title: "Editar",
        modal_form: true,
        product: product,
      };
    },
    closeModalUpdateSale() {
      this.modalUpdateSale = {
        modal_form: false,
      };
    },
    handleUpdateProduct(updatedProduct) {
      const index = this.productList.findIndex(
        (p) => p.product.id === updatedProduct.product.id
      );
      if (index !== -1) {
        this.$set(this.productList, index, updatedProduct); // Actualiza el producto en la lista
      }
    },
    showBead() {
      this.get({
        url:
          this.$store.getters.get__url +
          "/beads/showBead/" +
          this.$store.getters.get_campu.value,
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          let bead = response.data;
          this.sale.bead_id = bead.id;
        })
        .catch((errors) => {});
    },
    filterProductoBarcode() {
      if (this.searchBarcode) {
        // Filtrar los productos que coinciden con el código de barras
        this.filteredProductsBarcode = this.data_product.filter((product) =>
          product.barcodes.some(
            (barcode) => barcode.name === this.searchBarcode
          )
        );

        // Si se encuentran productos coincidentes, ejecutar la función para agregarlos
        if (this.filteredProductsBarcode.length > 0) {
          this.addProductoBarcode(this.filteredProductsBarcode);
        }
      }
    },
    addProductoBarcode(product) {
      const prod = product[0];
      const existingProduct = this.productList.find(
        (p) =>
          p.product.id === prod.value && p.unit.id === prod.value_unit
      );

      if (existingProduct) {
        // If the product exists, increment its quantity
        if (existingProduct.quantity < prod.stock) {
          existingProduct.quantity += 1;
        } else {
          Swal.fire({
            title: "La cantidad excede del stock disponible",
            icon: "warning",
          });
        }
      } else {
        // If the product doesn't exist, add it to the list
        const newProduct = {
          product: {
            id: prod.value,
            name: prod.label,
          },
          unit: {
            id: prod.value_unit,
            name: prod.label_unit,
          },
          price: prod.price,
          quantity: 1,
          subtotal: prod.price,
          stock: prod.stock,
        };
        this.productList.push(newProduct);
      }
    },
  },
};
</script>

<style scoped>
.bg-purple {
  background-color: #926cdc;
}
.bg-white {
  background-color: white;
}
.bg-gris {
  background-color: #dee2e6;
}
.bg-gray {
  background-color: #dee2e6;
}

.bg-green {
  background-color: #28a745;
}
.color-white {
  color: white;
}
.color-black {
  color: black;
}
.font-bold {
  font-weight: bold;
}
.container-card-body {
  background-color: #dee2e6;
  border-radius: 8px;
  cursor: pointer;
}
.lista {
  background-color: #ebedef;
  border-radius: 6px;
  width: 100%;
  list-style-type: none;
  margin: 0;
  padding: 0;
  margin-top: -10px;
}
.lista li {
  width: 100%;
  padding: 2px 0 2px 10px;
}
.lista li:hover {
  border-radius: 6px;
  background-color: #39f;
  list-style-type: none;
  color: #fff;
}
</style>