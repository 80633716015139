<template>
    <div v-if="boo_modal" class="modal fade show" style="display: block;">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Seleccionar Producto</h5>
            <button type="button" class="close" @click="closeModal">
              <span>&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <table class="table table-hover">
              <thead>
                <tr>
                  <th>Producto</th>
                  <th>Unidad</th>
                  <th>Stock</th>
                  <th>Precio</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(product, index) in products" :key="index">
                  <td>{{ product.label }}</td>
                  <td>{{ product.label_unit }}</td>
                  <td>{{ product.stock }}</td>
                  <td>
                    <button
                      @click="selectProduct(product)"
                      :disabled="product.stock == 0"
                      :class="product.stock == 0 ? 'btn btn-secondary btn-sm' : 'btn btn-primary btn-sm'"
                    >
                    {{ product.price }}
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" @click="closeModal">
              Cerrar
            </button>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      products: {
        type: Array,
        required: true,
      },
      boo_modal: {
        type: Boolean,
        required: true,
      },
    },
    methods: {
      closeModal() {
        this.$emit("close");
      },
      selectProduct(product) {
        if (product.stock > 0) {
          this.$emit("selectProduct", product);
        }
        // this.$emit("selectProduct", product);
      },
    },
  };
  </script>
  
  <style scoped>
  .modal {
    background-color: rgba(0, 0, 0, 0.5);
  }
  </style>
  