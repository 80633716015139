<template>
  <div>
    <ProccessVue v-if="page == 'proccess'" @changePage="changePage" :userRoles="userRoles" />
    <PurchaseVue class="pt-1" v-if="hasRoles([4]) && page=='purchase'" @returnHome="returnHome" />
    <SaleVue class="pt-1" v-if="hasRoles([4]) && page=='sale'" @returnHome="returnHome" />
    <StockVue class="pt-1" v-if="hasRoles([4]) && page=='stock'"  @returnHome="returnHome"/>
    <KardexVue class="pt-1" v-if="hasRoles([4]) && page=='kardex'" @returnHome="returnHome"/>
  </div>
</template>
  
<script>

import ProccessVue from '../../components/proccess/Home.vue';
import PurchaseVue from '../../components/proccess/purchase/page/Purchase.vue';
import SaleVue from '../../components/proccess/sale/page/Sale.vue';
import StockVue from '../../components/proccess/stock/page/Stock.vue';
import KardexVue from '../../components/proccess/kardex/page/Kardex.vue';

export default {
  components: {
    PurchaseVue,
    SaleVue,
    ProccessVue,
    StockVue,
    KardexVue,
  },
  data() {
    return {
      page: 'proccess',
      userRoles: this.$store.getters.get_roles.map(role => role.role.id)
    }
  },
  methods: {
    changePage(page) {
      this.page = page;
      this.$emit("mtdViewFooter", false, 'proccess');
    },
    returnHome() {
      this.page = 'proccess';
      this.$emit("mtdViewFooter", true, 'proccess');
    },
    hasRoles(roles) {
      return roles.some(role => this.userRoles.includes(role));
    }
  }
};
</script>
  